/* Main Pages
================================================== */
#home {
  header {
    position: absolute;
    background-color: transparent;
    @include bp(large) {
      position: fixed;
      padding: 2rem 0;
    }
    @include bp(large) {
      &.shrink {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        background-color: $primary-colour;
        .header__logo {
          &--1 {
            svg {
              width: 80px;
            }
          }
        }
      }
    }
    .header__logo {
      &--1 {
        svg {
          width: 120px;
          * {
            fill: $white;
          }
        }
      }
    }
  }
  &.primary-menu-active .nav {
    background-color: $primary-colour;
    //@include box-shadow(0px 0px 24px 16px rgba(20, 23, 71, 1));
  }
  .nav--primary {
    a {
      color: $white;
    }
    a:hover,
    .current > a,
    .active > a {
      border-color: $white;
    }
    .mod-menu__sub {
      background-color: $primary-colour;
    }
    .parent > a:after {
      border-color: $white transparent transparent;
    }
  }
  .event-list__wrap {
    background-color: $primary-colour;
    //@include box-shadow(0px 0px 24px 16px rgba(20, 23, 71, 1));
    @include bp(large) {
      background-color: transparent;
      @include box-shadow(none);
    }
    .subheading {
      color: $white;
    }
  }
}
