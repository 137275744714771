/* Banner / Slider
================================================== */
.banner {
  position: relative;
  z-index: 60;
  padding: 0;

  /* Global Banner Components
  ================================================== */
  &__row {
    position: relative !important;
    z-index: 10 !important;
  }

  &__shape,
  &__shape--2 {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    -webkit-transform: translate(-50%, -50%) !important;
    -ms-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important;
    align-self: auto !important;
    flex: none !important;
  }

  &__logo {
    display: flex;
    justify-content: flex-start;
    /*margin-bottom: 2rem !important;
    @include bp(mlarge) {
      margin-bottom: 3rem !important;
    }*/
    * {
      fill: $white !important;
      text-align: left;
    }
    .secondary-colour {
      fill: $secondary-colour !important;
    }
    svg,
    img {
      width: 100%;
      max-width: 80%;
      max-height: 72px;
      @include bp(mlarge) {
        max-width: 500px;
      }
    }
    &--large {
      svg,
      img {
        width: 100%;
        max-width: 100%;
        max-height: 100px;
        @include bp(mlarge) {
          max-width: 500px;
        }
      }
    }
  }

  &__date {
    .n2-ss-item-content {
      padding: 1rem;
      background-color: $primary-colour;
      text-align: right;
      @include bp(mlarge) {
        padding: 3rem;
      }
    }
    * {
      color: $white;
    }
    .strap {
      margin-bottom: 0.5rem;
    }
    h3 {
      margin-top: 0;
      margin-bottom: 0;
      @include bp_max(mlarge) {
        font-size: 1.6rem;
      }
    }
    &--black,
    &--white {
      .n2-ss-item-content {
        background-color: transparent;
      }
    }
    &--black {
      * {
        color: $primary-colour !important;
      }
    }
    &--white {
      * {
        color: $white !important;
      }
    }
    &--standard {
      .n2-ss-item-content {
        padding: 0;
        background-color: transparent;
        text-align: inherit;
      }
    }
  }

  &__button {
    border-radius: 8px !important;
    text-transform: uppercase !important;
    &.button--secondary {
      background: $tertiary-colour !important;
    }
    &.button--black {
      background: $primary-colour !important;
    }
    &.button--white {
      background: $white;
      color: $primary-colour !important;
      .ripple {
        background-color: $secondary-colour;
      }
    }
  }

  &__arrow-down {
    position: relative;
    overflow: visible;
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    margin: 0;
    span,
    div {
      position: absolute;
      display: block;
      border-radius: 8px;
    }
    img {
      position: relative;
      z-index: 10;
    }
    span {
      z-index: 5;
      width: 48px;
      height: 48px;
      border: 3px solid $white !important;
      border-radius: 8px;
      background-color: $primary-colour;
    }
    div {
      z-index: 0;
      width: 72px;
      height: 72px;
      background-color: $white;
      opacity: 0.5;
      -webkit-animation-name: zoomIn;
      animation-name: zoomIn;
      -webkit-animation-duration: 2s;
      animation-duration: 2s;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
    }
  }

  /* Home Large
  ================================================== */
  &--home-slider {
    .banner__content {
      .n2-ss-section-main-content {
        padding: 160px 0 40px !important;
        @include bp(large) {
          padding-top: 120px !important;
        }
      }
    }
    .banner__content--2 {
      .n2-ss-section-main-content {
        padding: 160px 0 40px !important;
        @include bp(large) {
          padding-top: 0px !important;
        }
      }
    }

    .banner__content--3 {
      .n2-ss-section-main-content {
        padding: 160px 0 75% !important;
        @include bp(mlarge) {
          padding-bottom: 50% !important;
        }
        @include bp(large) {
          padding: 160px 0 0 0 !important;
        }
      }
      .n2-ss-layer-row {
        height: 100%;
      }
      .banner__left {
        @include bp_max(large) {
          width: 100% !important;
        }
      }
    }

    .banner__row {
      & > .n2-ss-layer-row {
        & > .n2-ss-layer-row-inner {
          flex-wrap: nowrap !important;
          @include bp_max(mlarge) {
            flex-wrap: wrap !important;
          }
        }
      }
    }
    .banner__title {
      p {
        font-size: 3rem !important;
        @include bp(mlarge) {
          font-size: 3.5rem !important;
        }
      }
    }
    .banner__right {
      position: relative !important;
    }
    .banner__shape {
      z-index: 20 !important;
      &--1 {
        z-index: 22 !important;
        margin-left: -42%;
        margin-top: 26%;
        @include bp(mlarge) {
          margin-left: -43%;
          margin-top: 52%;
        }
        @include bp(large) {
          margin-left: -52%;
          margin-top: 39%;
        }
      }
      &--2 {
        margin-left: -43%;
        margin-top: 21%;
        @include bp(mlarge) {
          margin-left: -55%;
          margin-top: 58%;
        }
        @include bp(large) {
          margin-left: -57%;
          margin-top: 36%;
        }
      }
      &--3 {
        margin-left: 33%;
        margin-top: 4%;
        @include bp(mlarge) {
          margin-left: 38%;
          margin-top: 34%;
        }
        @include bp(large) {
          margin-left: 27%;
          margin-top: 24%;
        }
      }
      &--4 {
        z-index: 24 !important;
        margin-left: 40.5%;
        margin-top: -14%;
        @include bp(mlarge) {
          margin-left: -43%;
          margin-top: 52%;
        }
        @include bp(large) {
          margin-left: 46%;
          margin-top: -16%;
        }
      }
      &--5 {
        z-index: 25 !important;
        margin-left: 21%;
        margin-top: 30%;
        @include bp(mlarge) {
          margin-left: 20%;
          margin-top: 74%;
        }
        @include bp(large) {
          margin-left: 13%;
          margin-top: 56%;
        }
      }
    }
    /* Smaller Slider
    ================================================== */
    &--small {
      .banner__content {
        .n2-ss-section-main-content {
          padding: 120px 0 0 !important;
        }
      }
      .banner__row {
        margin-right: auto !important;
        @include bp(large) {
          max-width: 80% !important;
        }
        @include bp(xxlarge) {
          max-width: 60% !important;
        }
      }
      .banner__title {
        p {
          font-size: 2.4rem !important;
          @include bp(mlarge) {
            font-size: 3rem !important;
          }
        }
      }
    }

    &-v2 {
      .n2-ss-slide {
        @include bp_max(large) {
          height: auto !important;
        }
      }
    }
  }

  &__video-outer-row {
    width: 80% !important;
    height: 100% !important;
    right: 0 !important;
    left: auto !important;
    transform: none !important;
    @include bp_max(large) {
      height: 40% !important;
      width: 100% !important;
      bottom: 0 !important;
      top: auto !important;
    }
    @include bp_max(mlarge) {
      width: 130% !important;
    }
  }

  &__video-mask {
    position: relative !important;
    top: 50% !important;
    left: 50% !important;
    -webkit-transform: translate(-50%, -50%) !important;
    -ms-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important;
    z-index: 10 !important;
    display: inline-block;
    height: auto !important;
    margin-left: 20% !important;
    @include bp(large) {
      height: 800px !important;
    }
    @include bp(xlarge) {
      margin-left: 0 !important;
    }

    -webkit-mask-image: url('#{$image-directory}shape-1.svg');
    mask-image: url('#{$image-directory}shape-1.svg');

    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;

    -webkit-mask-position: center;
    mask-position: center;

    -webkit-mask-size: 100%;
    mask-size: 100%;
  }

  &__video-mask--2 {
    top: 53% !important;

    -webkit-mask-image: url('#{$image-directory}double-splash-graphic.svg');
    mask-image: url('#{$image-directory}double-splash-graphic.svg');

    -webkit-mask-size: 70%;
    mask-size: 70%;
  }

  &__video-mask--3 {
    -webkit-mask-image: url('#{$image-directory}new-video-mask.svg');
    mask-image: url('#{$image-directory}new-video-mask.svg');

    height: 100% !important;
  }

  &__video-overlay-image {
    &--1 {
      height: 82% !important;
      left: 0% !important;
      top: 24% !important;
      transform: none !important;
      .n2-ss-item-image-content,
      img {
        height: 100% !important;
      }
    }
    &--2 {
      height: 100% !important;
      right: -25% !important;
      transform: none !important;
      left: auto !important;
      .n2-ss-item-image-content,
      img {
        height: 100% !important;
      }
    }
  }

  /* Event Slider
  ================================================== */
  &--event-slider {
    .banner__content {
      .n2-ss-section-main-content {
        padding: 40px 0 !important;
        @include bp(large) {
          padding: 80px 0 !important;
        }
      }
    }
    .banner__title {
      p {
        font-size: 1.6rem !important;
        @include bp(mlarge) {
          font-size: 2rem !important;
        }
        @include bp(large) {
          font-size: 2.6rem !important;
        }
      }
      &--small {
        p {
          font-size: 1.2rem !important;
          @include bp(mlarge) {
            font-size: 1.4rem !important;
          }
          @include bp(large) {
            font-size: 1.6rem !important;
          }
        }
      }
    }
    .banner__content-wrap {
      @include bp_max(mlarge) {
        display: none !important;
      }
    }
    .banner__shape {
      margin-left: 0%;
      margin-top: 8%;
      @include bp(mlarge) {
        margin-top: 24%;
      }
      @include bp(large) {
        margin-top: 48%;
      }
      &--2 {
        margin-left: 0%;
        margin-top: 15%;
        @include bp(mlarge) {
          margin-top: 0%;
        }
        @include bp(large) {
          //margin-top: 48%;
        }
      }
      &--6,
      &--7 {
        width: 80% !important;
        height: 100% !important;
        right: 0;
        left: auto !important;
        transform: none !important;
        .n2-ss-item-image-content,
        img {
          width: 100% !important;
          height: 100% !important;
          object-fit: cover;
          object-position: left;
        }
        .n2-ss-item-image-content {
          text-align: right;
        }
      }
      &--7 {
        @include bp(medium) {
          width: 65% !important;
        }
      }
    }

    .banner__sponsor-title {
      width: 170px !important;
      min-width: 170px !important;
    }
  }

  &--larger-logo {
    .banner__logo {
      img,
      svg {
        max-height: 120px;
      }
    }
  }

  /* Video Full Banner
  ================================================== */
  &--video-full {
    .n2-ss-slider .n2-ss-slide-background-video {
      object-position: 50% 25%;
    }
  }
}

/* Arrow Animation
================================================== */
@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.75, 0.75, 0.75);
    transform: scale3d(0.75, 0.75, 0.75);
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.75, 0.75, 0.75);
    transform: scale3d(0.75, 0.75, 0.75);
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}
