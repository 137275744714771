/* Popup
================================================== */
.popup-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 1rem;
  align-items: center;
  justify-content: center;
  z-index: 999;

  @include bp_max(medium) {
    display: none !important;
  }

  .popup {
    position: relative;
    display: inline-block;
    width: 100%;
    max-width: 700px;
    background: url('#{$image-directory}pop-up-bg.webp') no-repeat 50% 50%;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    @include bp_max(medium) {
      font-size: 0.8em;
    }
    * {
      color: $white;
    }
    &__link {
      padding: 4em 2em;
    }
    &__logo {
      margin: 0 auto 1rem auto;
      @include bp_max(mlarge) {
        margin-top: 2rem;
      }
      img {
        display: block;
      }
    }
    &__logos {
      align-items: center;
      flex-wrap: wrap;
      img {
        width: auto;
        max-width: 140px;
        max-height: 72px;
        margin: 0 1rem;
      }
    }
    &--no-padding {
      .popup__link {
        padding: 0 !important;
      }
    }
  }

  .title {
    margin-bottom: 8px;
    @include bp_max(medium) {
      font-size: 1.4em;
    }
    &:after {
      display: none;
    }
  }

  h3 {
    margin-bottom: 1rem;
  }

  .button {
    margin-bottom: 0;
  }

  .close {
    position: absolute;
    top: 1em;
    right: 1em;
    height: auto;
    margin: 0;
    padding: 0.25rem 1rem;
    line-height: 100%;
    color: $white;
    text-decoration: none;
    text-transform: uppercase;
    font-family: sans-serif;
    &:after {
      display: none;
    }
  }

  // DCX
  &--2 {
    .popup {
      background: $black;
    }
  }
}
