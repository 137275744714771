/* Sections 
================================================== */
section {
  position: relative;
  overflow: hidden;
  padding: 3rem 0;
}

.bg-colour {
  padding: 3rem;
  @extend %border-radius-1;
  * {
    color: $white;
  }
  &--1 {
    background-color: $secondary-colour;
  }
  &--center {
    text-align: center;
  }
}

.bg-graphic {
  .bg-graphic__wrap {
    position: relative;
    overflow: hidden;
    padding: 3rem;
    @extend %border-radius-1;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 1.5rem;
    }
  }
  &--secondary,
  &--tertiary {
    * {
      color: $white;
    }
  }
  &--secondary {
    .bg-graphic__wrap {
      background-color: $secondary-colour;
    }
  }
  &--tertiary {
    .bg-graphic__wrap {
      background-color: $tertiary-colour;
    }
  }
  &--center {
    text-align: center;
  }
  &--1 {
    .bg-graphic__content {
      position: relative;
      z-index: 20;
    }
    .graphic-bg {
      position: absolute;
      z-index: 10;
      &--1 {
        left: -403px;
        top: -425px;
        @include bp(large) {
          left: -410px;
          top: -345px;
        }
        @include bp(xxlarge) {
          left: -394px;
          top: -274px;
        }
      }
      &--2 {
        right: -410px;
        top: -121px;
        @include bp(mlarge) {
          right: -317px;
          top: -121px;
        }
        @include bp(xxlarge) {
          right: -145px;
          top: -142px;
        }
      }
    }
  }
  &--2,
  &--3 {
    position: relative;
    overflow: hidden;
    padding: 3rem;
    text-align: center;
    @extend %border-radius-1;
    @extend %box-shadow;
    background-color: $white;
    .bg-graphic__content {
      position: relative;
      z-index: 20;
    }
    svg {
      position: absolute;
      z-index: 10;
      top: -320px;
      left: -375px;
    }
  }
  &--3 {
    svg {
      width: 638px;
      height: auto;
      top: 50px;
      right: -430px;
      left: auto;
      @include bp(smedium) {
        right: -378px;
      }
      @include bp(mlarge) {
        right: -430px;
      }
      @include bp(large) {
        right: -378px;
      }
    }
  }
}

/* Gallery / Testimoniaks 
================================================== */
.section {
  &--gallery-testimonials {
    .row {
      .column {
        position: relative;
        &::after {
          content: '';
          position: absolute;
          z-index: 10;
          top: 0;
          right: 0.9375rem;
          bottom: 6rem;
          left: 0.9375rem;
          margin: auto;
          background-color: $primary-colour;
          @extend %border-radius-1;
          @include bp(mlarge) {
            top: 6rem;
          }
        }
      }
    }
  }
}

.testimonials-slider {
  z-index: 30;
  top: 3rem;
  left: 2rem;
  width: 85% !important;
  margin-bottom: 8rem;
  @include bp(mlarge) {
    position: absolute !important;
    top: 9rem;
    left: 4%;
    width: 35% !important;
  }
  @include bp(xlarge) {
    top: 11rem;
    left: 8%;
  }
  &__logo {
    padding: 1rem;
    background-color: $white;
    @extend %border-radius-1;
  }
  &__quote {
    p {
      font-size: 1.2rem !important;
      @include bp(medium) {
        font-size: 1rem !important;
      }
      @include bp(xlarge) {
        font-size: 1.1rem !important;
      }
    }
  }
  &__icon {
    @include bp_max(medium) {
      left: auto !important;
      right: 0 !important;
    }
    img {
      @include bp_max(medium) {
        rotate: 180deg;
      }
    }
  }
  #n2-ss-6 {
    .n2-ss-layers-container {
      margin-right: auto;
    }
    /*.n2-ss-slide-limiter {
      max-width: 85% !important;
      @include bp(mlarge) {
        max-width: 35% !important;
      }
    }*/
    .n2-ss-layer[data-sstype='slide'] {
      @include bp_max(medium) {
        padding-left: 0 !important;
      }
    }
  }
  .n2-ss-control-bullet {
    top: 3rem !important;
    margin-left: 3.2rem !important;
  }
}

.gallery-slider {
  position: relative;
  z-index: 20;
  padding: 0 2rem;
  @include bp(medium) {
    padding: 0 4rem;
  }
  .n2-ss-slider-1 {
    width: 100%;
    overflow: hidden;
    margin-bottom: 5rem;
    margin-left: auto;
    @extend %border-radius-1;
    @extend %box-shadow;
    @include bp(mlarge) {
      width: 50%;
      margin-bottom: 7rem;
    }
    @include bp(large) {
      margin-bottom: 5rem;
    }
  }
  .nextend-thumbnail {
    overflow: visible !important;
  }
  .nextend-thumbnail-button {
    overflow: hidden;
    top: -30%;
    left: auto !important;
    width: auto !important;
    height: auto !important;
    padding: 1rem;
    background-color: $white;
    transform: none !important;
    visibility: visible !important;
    @extend %border-radius-2;
    @include bp(mlarge) {
      top: -21%;
    }
    @include bp(large) {
      top: -16%;
    }
    img {
      width: 22px !important;
    }
  }
  .nextend-thumbnail-previous {
    right: 80px;
  }
}

/* Tech Areas Slider
================================================== */
.tech-areas-slider {
  overflow-x: hidden;
  padding: 3rem 0;
  .n2-ss-slider-2,
  .n2-ss-slider-3 {
    overflow: visible !important;
  }
  .n2-ss-slide {
    overflow: hidden !important;
    border-radius: 8px !important;
    img {
      max-width: 100px !important;
    }
  }
}
