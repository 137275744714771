/* Event Links
================================================== */
.event-links {
  .newslist {
    .latestnews-items {
      justify-content: center !important;
    }
    .latestnews-item {
      text-align: center;
      flex: 0 0 100%;
      max-width: 100%;
      @include bp(mlarge) {
        flex: 0 0 50%;
        max-width: 50%;
      }
      & > a {
        display: block;
        text-align: center;
        background-color: $white;
        @extend %border-radius-1;
        @extend %box-shadow;
        &:hover {
          background-color: $primary-colour;
          * {
            color: $white;
            fill: $white;
          }
        }
      }
      // Delivery Logistics
      &.id-186 {
        svg {
          max-height: 88px;
        }
      }
      // DCX
      &.id-2 {
        svg {
          max-height: 120px;
        }
      }
    }
    .news {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent !important;
    }
    .newstitle {
      display: none;
    }
    .event-logo {
      width: 100%;
    }
    .newsinfo {
      padding: 3rem !important;
    }
    .newsextra {
      text-align: center;
      font-size: 1rem;
    }
    a,
    svg {
      display: block;
    }
    svg {
      max-height: 64px;
      width: 100%;
      * {
        fill: $primary-colour;
      }
    }
    // Location
    .detail_jfield_3 {
      display: block;
      margin-bottom: 1.5rem;
      font-size: 1rem;
      text-transform: uppercase;
    }
    // Date
    .detail_jfield_2 {
      display: block;
      margin-top: 1rem;
      font-size: 1.4rem;
      font-weight: 500;
    }
  }

  // Select Event
  header & {
    .newslist {
      .latestnews-items {
        flex-direction: column !important;
        width: 100%;
        margin-top: 1rem;
        @include bp(medium) {
          width: 400px;
        }
      }
      .latestnews-item {
        flex: 0 0 100% !important;
        max-width: 100% !important;
        padding: 0 0 1rem 0 !important;
        & > a {
          position: relative;
          overflow: hidden;
          background-color: $secondary-colour !important;
          * {
            color: $white !important;
            fill: $white !important;
          }
          .ripple {
            position: absolute;
            background: #fff;
            transform: translate(-50%, -50%);
            pointer-events: none;
            border-radius: 50%;
            animation: ripple 1s linear infinite;
          }
          @keyframes ripple {
            0% {
              width: 0;
              height: 0;
              opacity: 0.5;
            }
            100% {
              width: 500px;
              height: 500px;
              opacity: 0;
            }
          }
        }
      }
      .newsinfo {
        padding: 1.5rem !important;
      }
      .newsextra {
        text-align: left;
      }
      svg {
        max-width: 100%;
        width: auto;
        max-height: 40px;
        text-align: left;
      }
      // Location
      .detail_jfield_3 {
        margin-bottom: 0.5rem;
      }
      // Date
      .detail_jfield_2 {
        margin-top: 0.75rem;
      }
    }
  }
}
.event-list {
  &__wrap {
    display: none;
    position: absolute;
    top: -0.5rem;
    right: 0;
    z-index: 100;
    width: 100%;
    height: 55vh;
    padding: 0.5rem 0.5rem 2rem 0.5rem;
    background-color: $white;
    //@include box-shadow(0px 0px 24px 16px rgba(255, 255, 255, 1));
    @include bp(large) {
      height: 75vh;
      top: 100%;
      right: 0.5rem;
      width: 415px;
      padding: 0;
      background-color: transparent;
      @include box-shadow(none);
    }
    .select-menu-active & {
      display: block;
    }
    .subheading {
      position: absolute;
      color: $primary-colour;
      top: 1.5rem;
      left: 1.5rem;
    }
  }
  &__inner {
    position: absolute;
    top: 5rem;
    right: 0;
    overflow-y: scroll;
    height: calc(100% - 5rem);
    padding-bottom: 2rem;
    @include bp(large) {
      top: 0;
      height: 100%;
      overflow-y: visible;
    }
  }
  &__scroll {
    position: absolute;
    right: 15px;
    left: 0;
    bottom: 0;
    z-index: 90;
    text-align: center;
    text-transform: uppercase;
    color: $white;
    font-size: 0.8rem;
    background-color: $primary-colour;
    padding: 0.25rem;
    @include bp(medium) {
      width: 400px;
      left: auto;
    }
    @include bp(large) {
      display: none;
    }
  }
}
.button--select-event-close {
  margin-top: 0.6rem !important;
  @include bp(large) {
    display: none !important;
  }
}

/* Sidebar Links
================================================== */
.events-sidebar {
  position: fixed;
  left: 100%;
  top: 30%;
  z-index: 90;
  transform-origin: 0% 100%;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg) translateX(-50%);
  @include bp_max(xlarge) {
    display: none;
  }
  &__wrap {
    display: flex;
  }
  .event-logo {
    display: inline-flex;
    &--hide {
      display: none !important;
    }
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 260px;
      height: 64px;
      padding: 0.5rem;
      background: $white;
      border: 3px solid $primary-colour;
      &:hover {
        background: $primary-colour;
        border-color: $white;
        svg {
          * {
            fill: $white;
          }
          .secondary-colour {
            fill: $secondary-colour;
          }
          .tertiary-colour {
            fill: $tertiary-colour;
          }
        }
      }
      img,
      svg {
        max-height: 30px;
        max-width: 100%;
        max-width: 225px;
        width: 100%;
        * {
          fill: $primary-colour;
        }
      }
    }
  }
}
