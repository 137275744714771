/* Main
================================================== */
main {
  padding: 6rem 0;
  @include bp(xlarge) {
    padding-right: 4rem;
    padding-left: 4rem;
  }
  ul {
    padding-left: 2rem;
    list-style: circle;
  }
}

.label-warning {
  display: none;
}

%box-shadow {
  @include box-shadow(8px 8px 40px 0px rgba(33, 33, 33, 0.2));
}
%border-radius-1 {
  overflow: hidden;
  border-radius: 8px;
}
.border-radius {
  @extend %border-radius-1;
}
%border-radius-2 {
  overflow: hidden;
  border-radius: 4px;
}

.strap,
.strap p {
  margin-bottom: -1rem;
  text-transform: uppercase;
  color: $secondary-colour;
  span {
    display: inline-block;
    margin: 0 1rem;
  }
  &.white {
    color: $white;
  }
}

%subheading {
  position: relative;
  display: inline-flex;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 1rem;
  &:after {
    content: "";
    position: absolute;
    top: 150%;
    width: 80%;
    height: 4px;
    background-color: $secondary-colour;
  }
}
.subheading {
  @extend %subheading;
}

.secondary-colour {
  color: $secondary-colour !important;
  fill: $secondary-colour !important;
}

.tertiary-colour {
  color: $tertiary-colour !important;
  fill: $tertiary-colour !important;
}
