/* Sponsors Section
================================================== */
.sponsors-section {
  &__wrap {
    padding: 3rem;
    background-color: $white;
  }
  h6 {
    @extend %subheading;
  }
  /*.sponsor {
    &__logo {
      img {
        max-height: 80px !important;
        width: auto;
      }
    }
  }*/
  [data-sstype='layer'] {
    img {
      max-height: 80px !important;
      width: auto;
    }
  }
}

/* Footer
================================================== */
footer {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-top: 3rem;
  padding: 10em 0 0;
  background: $primary-colour;
  @include bp(large) {
    padding-top: 6rem;
  }
  * {
    color: $white;
  }
  a:hover {
    color: $secondary-colour;
  }
  .button {
    margin: 2rem 0 0 0;
    font-size: 0.9rem;
  }
}

.footer {
  &__outer-wrap {
    position: relative;
    z-index: 20;
  }
  &__wrap {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    text-align: center;
    justify-content: space-between;

    & > div {
      @include bp_max(medium) {
        text-align: center;
      }
      @include bp_max(xlarge) {
        margin-bottom: 3rem;
      }
    }

    h3 {
      margin: 0 0 2rem;
      font-size: 1.2rem;
      font-weight: 600;
    }
    h4 {
      margin-bottom: 0.5rem;
      font-size: 1.2rem;
      font-weight: normal;
    }
  }

  &__logo {
    img {
      max-width: 220px;
      height: auto;
      margin: 0 auto 2rem;
      @include bp(xlarge) {
        margin: 0 0 2rem 0;
      }
    }
    .social {
      svg {
        width: 1.6rem;
        height: 1.6rem;
        path {
          fill: $white;
        }
      }
    }
  }

  &__quick-links,
  &__menu {
    text-align: left;
    svg {
      width: auto;
      max-height: 72px;
      max-width: 260px;
      margin-bottom: 2rem;
      * {
        fill: $white !important;
      }
    }
    a {
      color: $white;
      &:hover {
        color: $secondary-colour;
      }
    }
  }

  &__menu {
    align-self: flex-start;

    &--2 {
      @include bp(medium) {
        margin-right: 5rem;
      }
    }

    .nav {
      display: flex !important;
      flex-wrap: wrap;
      align-items: normal;
      & > li {
        flex: 0 0 100%;
        max-width: 100%;
        margin: 0 0 2rem 0;
        @include bp(smedium) {
          flex: 0 0 50%;
          max-width: 50%;
        }
        @include bp(mlarge) {
          flex: 0 0 33.3333333333%;
          max-width: 33.3333333333%;
        }
        @include bp(xlarge) {
          flex: 0 0 16.6666666667%;
          max-width: 16.6666666667%;
        }
      }

      // Hide triangle for dropdown
      .parent > a:after {
        display: none;
      }

      &--full {
        .nav {
          & > li {
            flex: 0 0 100% !important;
            max-width: 100% !important;
          }
        }
      }
      // Hide Button Links (class only available on anchor, not li)
      .item-132,
      .item-133,
      .item-196 {
        display: none;
      }
      // Hide Search Links
      .item-126,
      .item-131 {
        display: none;
      }

      // Hide border colour
      a:hover,
      .current > a,
      .active > a {
        border-color: transparent;
        &:after {
          border-color: transparent;
        }
      }

      a {
        text-align: left;
        &:hover {
          color: $tertiary-colour;
        }
      }
      & > li > a {
        margin-bottom: 1rem;
        text-transform: capitalize;
        font-weight: bold;
      }
      .mod-menu__sub {
        display: block !important;
        a {
          font-size: 0.8rem;
        }
      }
    }
    .button--ticket,
    .nav__search {
      display: none !important;
    }
  }

  &__social {
    margin: 0 !important;
    @include bp(large) {
      padding-right: 4rem;
    }
    ul {
      @include bp(large) {
        flex-flow: column;
      }
      li {
        @include bp(large) {
          margin-left: auto;
        }
        a {
          display: block;
          padding: 1rem;
        }
        svg {
          width: 1.6rem;
          height: 1.6rem;
          path {
            fill: $white;
          }
        }
      }
    }
  }

  &__logo {
    order: 3;
    @include bp(large) {
      order: 1;
    }
  }

  &__quick-links,
  &__menu {
    order: 1;
    @include bp(large) {
      order: 2;
    }
  }

  &__social {
    order: 4;
    @include bp(large) {
      order: 4;
    }
  }

  &__graphic {
    position: absolute;
    z-index: 10;
    &--1 {
      bottom: -220px;
      right: -480px;
    }
    &--2 {
      left: -772px;
      top: -555px;
      @include bp(xxxlarge) {
        top: -440px;
      }
    }
    &--3 {
      top: -90px;
      left: -200px;
      @include bp(medium) {
        top: -100px;
        left: -300px;
      }

      @include bp(xxlarge) {
        left: -200px;
      }
    }

    &--4 {
      right: -200px;
      top: -400px;
      height: 200%;
      @include bp(medium) {
        right: -350px;
        top: 300px;
        height: 100%;
      }

      @include bp(large) {
        top: 0;
        right: -150px;
      }

      @include bp(xxlarge) {
        right: 0;
      }
    }

    &--5 {
      //height: 100%;
      width: 800px;
      height: 700px;
      right: -140px;
      bottom: -200px;
      //top: 1000px;
      //right: -111px;

      /*@include bp(medium) {
        top: 300px;
        right:-350px;
      }*/

      @include bp(medium) {
        bottom: -320px;
      }

      /*@include bp(xxlarge) {
        top: 150px;
        right: -200px;
      }*/
    }
    &--6 {
      width: 100%;
      bottom: 60px;
    }
  }

  &--dark {
    background-color: #111111;
  }

  &__bottom {
    position: relative;
    z-index: 30;
    width: 100%;
    margin-top: 6rem;
    padding: 1rem 0;
    background-color: darken($primary-colour, 6%);

    * {
      text-transform: uppercase;
    }

    .row {
      justify-content: space-between;
      align-items: center;
    }

    .footer__links,
    .footer__signature {
      font-size: 0.8em;
      @include bp_max(medium) {
        padding: 0.5rem 0;
        text-align: center;
      }
    }

    .footer__links {
      @include bp(medium) {
        justify-content: flex-start;
      }
      a {
        margin-right: 1rem;
        &:hover {
          color: $tertiary-colour;
        }
      }
    }

    .footer__signature {
      display: flex;
      align-items: center;
      justify-content: center;
      @include bp(medium) {
        justify-content: flex-end;
      }
      img {
        margin-left: 1rem;
        width: 14em;
        @include transition($global-transition);
      }
    }
  }
}

// Cookie settings button
#ot-sdk-btn.ot-sdk-show-settings,
#ot-sdk-btn.optanon-show-settings {
  padding: 0 !important;
  color: $white !important;
  font-size: 1em !important;
  border: none !important;
  background-color: transparent !important;
  transition: 0.5s ease-in-out !important;
  &:hover {
    color: $tertiary-colour !important;
  }
}
