/* Header
================================================== */
header {
  position: relative;
  z-index: 100;
  top: 0;
  right: 0;
  left: 0;
  padding: 0.5rem 0;
  background-color: $white;
  @include transition($global-transition);
  .row {
    align-items: center;
  }
}

.header {
  &__logos {
    a {
      display: flex;
      align-items: center;
    }
    &-text {
      width: 100px;
      margin: 0 0 0 1rem;
      padding: 0;
      text-transform: uppercase;
      color: $primary-colour;
      #home & {
        color: $white;
      }
    }
    &-inner {
      display: none;
      align-items: center;
      @include bp(mlarge) {
        display: inline-flex;
      }
      @include bp(large) {
        display: none;
      }
      @include bp(xlarge) {
        display: inline-flex;
      }
    }
  }
  &__logo {
    margin: 0 1rem;
    svg {
      height: auto;
      @include transition($global-transition);
      * {
        fill: $primary-colour;
      }
    }
    &--1 {
      display: none;
      margin-left: 0;
      svg {
        width: 80px;
        * {
          fill: $primary-colour;
        }
        .tertiary-colour {
          fill: $tertiary-colour !important;
        }
      }
    }
    &--2,
    &--3 {
      svg {
        #home & {
          * {
            fill: $white;
          }
        }
      }
    }
    &--2 {
      svg {
        width: 80px;
      }
    }
    &--3 {
      svg {
        width: 135px;
      }
    }
  }
}
