/* News
================================================== */
.newslist {
  .latestnews-items {
    flex-flow: column !important;
    align-items: normal !important;
    -webkit-justify-content: normal !important;
    -ms-flex-pack: normal !important;
    justify-content: normal !important;
    overflow: visible !important;
    @include bp(medium) {
      flex-flow: row wrap !important;
    }
  }
  .latestnews-item {
    flex: 1;
    @extend .columns;
    @extend .small-12;
    padding: 0 0 2rem !important;
    @include bp(medium) {
      flex: 0 0 50%;
      max-width: 50%;
      padding-right: 1rem !important;
      padding-left: 1rem !important;
    }
    @include bp(large) {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    & > a {
      display: block;
      height: 100%;
      &:hover {
        .detail_category,
        .detail_tags {
          opacity: 1;
        }
        .link {
          a {
            background-color: $secondary-colour;
            span {
              color: $white;
            }
          }
        }
      }
    }
    .newsSection & {
      border: 0;
    }
    &.active {
      opacity: 1 !important;
    }
  }
  .newshead {
    float: none !important;
    margin: 0 !important;
  }
  picture {
    display: block;
  }
  .innerpicture {
    width: 100%;
    &:after {
      content: '';
      display: block;
      padding-bottom: 60%;
    }
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .news {
    position: relative !important;
    height: 100% !important;
    margin: 0 !important;
    padding: 0rem !important;
    background-color: $white !important;
    border: none !important;
    @extend %border-radius-1;
    @extend %box-shadow;
    overflow: hidden;
  }
  .newstitle {
    font-size: 1.6rem;
    font-family: $primary-font !important;
    font-weight: normal !important;
    line-height: $base-heading-line-height !important;
    margin: 0.5rem 0 !important;
    a {
      color: $primary-colour;
      &:hover {
        color: $secondary-colour;
      }
    }
  }
  .newsinfo {
    padding: 1.5rem 1rem 4rem !important;
    * {
      color: $primary-colour;
    }
  }
  .newsintro {
    line-height: $base-line-height !important;
  }
  .detail_category,
  .detail_tags {
    opacity: 1;
    display: inline-flex;
    margin-right: 0.5rem;
    padding: 0.4rem 0.8rem;
    border-radius: 50px;
    background: $off-white;
    font-family: $primary-font;
    font-weight: 700;
    font-size: 0.8rem;
    color: $primary-colour;
    transition: $global-transition;
    @include bp(medium) {
      opacity: 0.25;
    }
  }
  .detail_date {
    display: inline-flex;
    align-items: center;
    margin: 0 0 0.25rem 0;
    font-size: 0.8rem;
    span {
      font-weight: 500;
      color: $dark-grey;
    }
  }
  .delimiter {
    display: none;
  }
  .link {
    width: 100% !important;
    margin-top: 1.5em !important;
    a {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 0.5rem 0;
      background-color: $primary-colour;
      text-align: center;
      font-family: $primary-font;
      text-transform: uppercase;
      font-weight: 400;
    }
    span {
      color: $white;
      transition: $global-transition;
    }
  }
  .items_pagination {
    margin-top: 2rem !important;
    a {
      display: inline-block;
      width: 40px;
      margin: 0 10px 0 0 !important;
      padding: 10px 0;
      background-color: $light-grey;
      border-radius: 50%;
      box-sizing: content-box;
      color: $primary-colour;
      cursor: pointer;
      font-weight: bold;
      line-height: 18px;
      text-align: center;
      &:hover {
        color: $white;
        background-color: $primary-colour;
      }
    }
    .active_page {
      text-decoration: none !important;
      a {
        color: $white;
        background-color: $primary-colour;
      }
    }
  }
  &--full-width {
    .latestnews-item {
      @include bp(medium) {
        flex: 0 0 100% !important;
        max-width: 100% !important;
      }
    }
  }
  &--full-details {
    .latestnews-item {
      .newsinfo {
        padding: 1.5rem 1rem !important;
      }
      ul,
      p {
        margin-bottom: 1rem !important;
      }
    }
  }
  &--contain {
    .picture {
      margin: auto;
    }
    .innerpicture {
      img {
        object-fit: contain;
      }
    }
  }
  &--size-down {
    .picture {
      margin: auto;
    }
    .innerpicture {
      img {
        object-fit: scale-down;
        max-width: 80% !important;
        margin: 5% 10% !important;
      }
    }
  }
  &--small-card {
    .newshead {
      margin-top: 1.5rem !important;
    }
    .newsinfo {
      padding: 1.5rem !important;
    }
  }
  &--center {
    .newstitle,
    .newsintro {
      text-align: center !important;
    }
  }
  &--quotes {
    .latestnews-item {
      flex: 0 0 100% !important;
      max-width: 100% !important;
      @include bp(mlarge) {
        flex: 0 0 50% !important;
        max-width: 50% !important;
      }
    }
    .newslist__quote {
      position: absolute;
      width: 40px;
      height: 40px;
      top: 2rem;
      left: 2rem;
    }
    .picturetype {
      position: absolute !important;
      top: 1rem;
      right: 1rem;
      width: 200px;
      height: 120px;
    }
    .picture {
      margin: auto;
    }
    .innerpicture {
      img {
        object-fit: scale-down;
        max-width: 80% !important;
        margin: 5% 10% !important;
      }
    }
    .newsinfo {
      margin-top: 8rem;
      padding: 2rem !important;
      font-size: 1.1rem;
    }
    .newsextra {
      display: block;
      margin-top: 2rem !important;
      font-size: 0.8rem !important;
      .detail_data {
        display: inline-flex;
        margin-right: 0.25rem;
        text-transform: uppercase;
      }
    }
  }
  &--profiles {
    .picture {
      width: 100%;
      height: 100%;
      max-width: none !important;
      max-height: none !important;
    }
    .innerpicture,
    picture {
      width: 140px;
      height: 140px;
      margin: auto;
      overflow: hidden;
      border-radius: 50%;
    }
    img {
      position: static !important;
      filter: gray; /* IE6-9 */
      -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
      filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
    }
  }
}

/* News Page
  ================================================== */
.news-item-article,
.article--narrow {
  .main-content {
    flex-direction: column;
    padding: 0;
  }
  .published {
    margin: 0;
    font-family: $primary-font;
    text-transform: uppercase;
    font-weight: 400;
  }
  .com-content-image,
  .item-page {
    max-width: 960px;
    width: 100%;
    margin: auto;
  }
  .com-content-image {
    margin-bottom: 0;
    border-bottom: 2px solid $base-background-color;
    figure {
      position: relative;
      margin: 0;
      background-color: $white;
    }
    img {
      display: block;
      width: 100%;
      height: 100%;
      max-height: 600px;
      object-fit: cover;
    }
  }
  .item-page {
    padding: 3em;
    background-color: $white;
    * {
      color: $base-font-color;
    }
  }
  .page-header {
    h1 {
      margin-top: 0;
    }
  }
  .article-info.muted {
    display: block;
    color: $primary-colour;
    margin-bottom: 2em;
  }
}

.article {
  &--image-contain {
    .com-content-image {
      figure {
        padding: 3rem;
      }
      img {
        object-fit: contain;
        max-height: 400px;
      }
    }
  }
}

.sharethis-inline-share-buttons {
  margin-top: 4rem;
  margin-bottom: 2rem;
}

.article-info.muted {
  display: none;
}
