/* Components
================================================== */

/* Social
================================================== */
.social {
  ul {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      width: auto;
      margin-bottom: 0;

      svg {
        width: 1.4em;
        height: 1.4em;

        path {
          fill: $secondary-colour;
        }

        * {
          @include transition($global-transition);
        }

        &:hover {
          path {
            fill: $tertiary-colour;
          }
        }
      }
    }
  }
}

/* Sidebar
================================================== */
.sidebar {
  position: relative;
  @include bp_max(mlarge) {
    margin-top: 3rem;
  }
  .moduletable {
    margin-bottom: 3rem;
  }
  /*aside {
    position: relative;
    overflow: hidden;
    padding: 3rem;
    text-align: center;
    @extend %border-radius-1;
    @extend %box-shadow;
    background-color: $white;
  }
  &__graphic {
    &--1 {
      .sidebar__content {
        position: relative;
        z-index: 20;
      }
      svg {
        position: absolute;
        z-index: 10;
        top: -320px;
        left: -375px;
      }
    }
  }*/
  &--opening-times {
    h6 {
      margin-bottom: 0.25rem;
      font-size: 1rem;
    }
    h5 {
      margin-top: 0;
    }
    .button {
      margin: 0;
    }
  }
}

/* Cards
================================================== */
.cards {
  gap: 3rem;
  margin-bottom: 3rem;
  .wf-column {
    position: relative;
    padding: 2rem 2rem 7rem 2rem;
    background-color: $white;
    @extend %border-radius-1;
  }
  figure {
    overflow: hidden;
    width: calc(100% + 4rem);
    height: 320px !important;
    margin-top: -2rem !important;
    margin-left: -2rem !important;
    padding-bottom: 0.5rem;
    @extend %border-radius-1;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    img {
      width: 100% !important;
      height: 100% !important;
      object-position: center !important;
      object-fit: cover !important;
    }
  }
  .button {
    position: absolute;
    bottom: 2.5rem;
    left: 2rem;
    margin: 0;
  }
  // Hover text overlay effect
  &--hover {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    article {
      transition: $global-transition;
    }
    figure {
      position: absolute !important;
      inset: 0;
      height: auto !important;
      margin: 0 !important;
      padding: 0 !important;
    }
    article {
      padding: 0 2rem 7rem;
      background: $primary-colour;
      transform: translateY(100%);
      * {
        color: $white;
      }
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      position: relative;
      left: -2rem;
      width: calc(100% + 4rem);
      margin: 0;
      padding: 2rem;
      background: $primary-colour;
      border-bottom: 2px solid $primary-colour;
      transform: translateY(-100%);
      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 1rem;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid $white;
        transform: translateX(-50%);
        opacity: 0.5;
      }
    }
    .wf-column {
      display: flex;
      overflow: hidden;
      padding: 0;
      &:hover {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        article {
          transform: translateY(0);
        }
      }
    }
  }
  &--four-columns {
    gap: 2rem !important;
    @include bp_max(xlarge) {
      display: flex !important;
      flex-direction: row !important;
      flex-wrap: wrap !important;
      gap: 0 !important;
      justify-content: space-between !important;
    }
    .wf-column {
      @include bp_max(xlarge) {
        flex: initial !important;
        flex-basis: calc(50% - 1rem) !important;
        margin-bottom: 2rem !important;
      }
      @include bp_max(mlarge) {
        flex-basis: calc(100%) !important;
      }
    }
  }
}

/* Accordion
================================================== */
.accordion {
  padding: 0;
}
.accordion-title {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
  &:before {
    margin-top: -0.75rem;
    font-size: 1.5rem;
  }
}

/* Box Links
================================================== */
/* JCE Fix
.box-links {
  cursor: inherit;
  &--large {
    cursor: inherit;
  }
}*/
.box-links {
  .wf-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    padding: 2rem;
    text-align: center;
    background-color: $primary-colour;
    @extend %border-radius-1;
    * {
      color: $white;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 0;
    }
    &.box-link {
      align-items: stretch;
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 10%;
        background-color: $secondary-colour;
        @extend %border-radius-1;
        @include transition($global-transition);
        &:hover {
          background-color: darken($secondary-colour, 8%);
        }
      }
    }
  }
  &--large {
    .wf-column {
      padding: 0;
    }
    * {
      font-size: 1.4rem;
      text-transform: uppercase;
      font-weight: bold;
    }
    p {
      margin: 10%;
      max-width: 80%;
    }
  }
}

/* JCE Fix
.box-background {
  cursor: inherit;
  &--1,
  &--2,
  &--3 {
    cursor: inherit;
  }
}*/
.box-background {
  .wf-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    padding: 2rem;
    text-align: center;
    background-color: $primary-colour;
    @extend %border-radius-1;
    * {
      color: $white;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 0;
    }
    ul {
      list-style-position: inside;
    }
    .button {
      margin-bottom: 0;
    }
  }
  &--1 {
    .wf-column {
      background-color: $secondary-colour;
    }
  }
  &--2 {
    .wf-column {
      background-color: darken($tertiary-colour, 8%);
    }
  }
  &--3 {
    .wf-column {
      background-color: $grey;
    }
  }
  &--4 {
    .wf-column {
      background-color: $black;
    }
  }
}

/* Slider Bullet Transition
================================================== */
.n2-bullet {
  transition: all 0.5s ease-in-out !important;
}

/* Slider Gallery
================================================== */
.slider-gallery-small {
  .n2-ss-slider-1 {
    @extend %border-radius-1;
  }
}

/* Feedback Popup
================================================== */
._hj_feedback_container {
  ._hj-Pbej5__styles__resetStyles input[type='submit'],
  ._hj-Pbej5__styles__resetStyles input[type='button'],
  ._hj-Pbej5__styles__resetStyles button {
    padding: 0 !important;
  }
}

/* Cookie Settings
================================================== */
#ot-sdk-btn-floating {
  margin-left: 84px;
  margin-bottom: 1rem;
  button {
    display: flex !important;
    padding: inherit !important;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }
}

/* Survey Settings
================================================== */
/*#hj-survey-toggle-1 {
  padding: inherit !important;
}*/
._hj-widget-container {
  button {
    padding: inherit !important;
  }
}
