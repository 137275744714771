/* Navigation
================================================== */
.nav {
  display: none;
  width: auto;
  margin: 0;
  padding: 0;

  @include bp(mlarge) {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }

  li {
    position: relative;
    margin-bottom: 0;
    text-align: center;
    list-style: none;
    @include bp_max(large) {
      width: auto;
      margin: 2rem;
      text-align: center;
    }
  }

  a {
    display: block;
    margin: 0;
    padding: 0.5rem 1rem;
    color: $base-font-color;
    font-size: 1rem;
    text-decoration: none;
    text-transform: uppercase;
    line-height: 1;
    border: 3px solid transparent;
    @extend %border-radius-1;
    &.button {
      margin: 0;
      border: 0;
      &--ticket {
        position: relative;
        display: inline-flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 1rem 2rem !important;
        font-weight: bold;
        @include bp(mlarge) {
          border-radius: 0;
          box-shadow: none;
        }
        &:hover {
          img {
            @include rotate(10);
          }
          &:after {
            @include bp(mlarge) {
              border-color: $black transparent transparent transparent;
            }
          }
        }
        /*&:before {
          @include bp(mlarge) {
            content: "";
            position: absolute;
            z-index: 9;
            top: 0;
            left: 100%;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 86px 44px 0 0;
            border-color: $secondary-colour transparent transparent transparent;
            @include transition($global-transition);
          }
        }*/
        img {
          width: 52px;
          margin-right: 0.75rem;
          @include transition($global-transition);
        }
      }
    }
    &.nav__search {
      position: relative;
      padding-left: 2rem;
      img {
        position: absolute;
        top: 50%;
        right: 10%;
        left: auto;
        transform: translateY(-50%);
        @include bp(large) {
          right: auto;
          left: 10%;
        }
      }
    }
    &.disable {
      cursor: default;
      border-color: transparent !important;
      &:hover {
        color: $white !important;
      }
      &:after {
        display: none;
      }
    }
    &.hidden {
      display: none !important;
    }
  }

  a:hover,
  .current > a,
  .active > a {
    border-color: $primary-colour;
    &:after {
      border-color: $primary-colour;
    }
  }

  ul {
    display: none;
  }

  .parent {
    > a {
      position: relative;
      padding-right: 2rem;

      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        right: 10%;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 7px 5px 0 5px;
        border-color: $primary-colour transparent transparent transparent;
        transition: $global-transition;
      }
    }

    &:hover {
      .mod-menu__sub {
        @include bp(large) {
          display: flex;
        }
      }
    }
  }

  .mod-menu__sub {
    display: none;
    flex-flow: column nowrap;
    list-style: none;
    width: 100%;
    margin: 0;
    padding: 0;
    &.active {
      @include bp_max(large) {
        display: flex;
      }
    }
    li {
      width: 100%;
      margin: 1rem 0;
    }
  }

  .nav-active & {
    display: flex;
    width: 100%;
    height: 100vh;
    position: absolute;
    margin-left: 0;
    left: 0;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    padding-top: 8em;

    @include bp_max(smedium) {
      padding-top: 5em;
    }
  }
}

/* Primary / Secondary Navigation
================================================== */
.nav--main {
  position: relative;
  font-size: 0.8em;
  .nav {
    @include bp_max(large) {
      display: block;
      position: absolute;
      top: 100%;
      right: 0;
      left: 0;
      height: 0;
      overflow: hidden;
      @include opacity(0);
      @include transition($global-transition);
      li {
        display: block;
      }
    }
    .mod-menu__sub {
      padding: 1rem 0 0 0;
      background-color: $white;
      @include bp(large) {
        width: 200%;
        max-width: 240px;
        padding: 1rem 0;
      }
      li {
        width: calc(100% - 2rem);
        margin: 0.5rem 1rem;
      }
    }
  }
}

/* Primary Navigation
================================================== */
.nav--primary {
  .nav {
    @include transition(background-color 0.5s ease-in-out);
    @include bp(mlarge) {
      justify-content: flex-end;
      align-items: center;
    }
    .primary-menu-active & {
      height: auto;
      background-color: $white;
      @include opacity(1);
    }
    a {
      @include bp(large) {
        margin: 0 2rem;
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
    .mod-menu__sub {
      background-color: $white;
      @include bp(large) {
        width: 150%;
      }
      a {
        margin: 0;
      }
    }
    .parent > a:after {
      border-color: $primary-colour transparent transparent;
    }
  }
  .nav-wrap {
    position: relative;
    z-index: 100;
    padding-right: 0.5rem;
    @include bp_max(large) {
      display: flex;
      align-items: center;
      position: static;
    }
  }
  ul {
    text-align: right;
  }
  .parent {
    ul {
      @include bp(large) {
        position: absolute;
        top: 100%;
        align-items: flex-start;
        background: $light-grey;
      }
    }
  }
}

/* Secondary Navigation
================================================== */
.nav--secondary {
  top: 0;
  left: 0;
  right: 0;
  z-index: 90;
  background-color: $white;
  @include box-shadow(none);
  @include transition(box-shadow 0.5s ease-in-out);
  .secondary-menu-active & {
    background-color: $white;
  }
  &.shrink {
    @include box-shadow(0 0 10px rgba(20, 23, 71, 0.2));
    @include bp(medium) {
      position: fixed;
      background-color: $white;
    }
    .nav,
    .nav ul {
      background-color: $white;
    }
  }
  &.shrink-animate {
    .button--ticket {
      img {
        width: 32px;
      }
    }
  }
  .column {
    position: relative;
    padding-left: 0;
    padding-right: 0;
    @include bp(xlarge) {
      margin-right: 4rem;
    }
    @include bp_max(large) {
      padding: 1rem 0;
    }
  }
  .nav {
    @include transition(background-color 0.5s ease-in-out);
    @include bp_max(mlarge) {
      background-color: rgba(255, 255, 255, 0.8);
    }
    .secondary-menu-active & {
      height: auto;
      @include opacity(1);
      background-color: $white;
    }
    li {
      flex-grow: 0;
    }
  }
  a {
    text-align: center;
    @include bp_max(large) {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }

  /*a:hover,
  .current > a,
  .active > a {
    &:after {
      border-color: $tertiary-colour transparent transparent transparent;
    }
  }*/

  .parent {
    ul {
      @include bp(large) {
        position: absolute;
        top: 100%;
        align-items: flex-start;
      }
    }
  }

  .alias {
    @include bp(large) {
      display: none;
    }
  }
}

/* Hamgurger Menu
================================================== */
.nav-button {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  margin: 0 0 0 auto;
  padding: 0;
  outline: 0;
  cursor: pointer;

  p {
    margin: 0;
    padding: 0 2.5rem 0 0;
    color: $white;
  }

  div {
    position: absolute;
    top: 47%;
    right: 3rem;
    transform: translateY(-50%);
  }

  span,
  span:before,
  span:after {
    content: "";
    display: block;
    position: absolute;
    width: 2em;
    height: 4px;
    background: $white;
    @include border-radius(1px);
    @include transition($global-transition);
  }

  span {
    &:before {
      top: -10px;
    }
    &:after {
      top: auto;
      bottom: -10px;
    }
  }

  &.active {
    span {
      background: transparent;
      &:before,
      &:after {
        top: 0;
      }
      &:before {
        @include rotate(45);
      }
      &:after {
        @include rotate(-45);
      }
    }
  }

  @include bp(large) {
    display: none;
  }
}

/* Navigation Search
================================================== */
#nav-search {
  display: flex;
  align-items: center;
  position: absolute;
  z-index: -1;
  right: 0;
  top: 0;
  height: 100%;
  padding: 0 0 0 6rem;
  background: $white;
  opacity: 0;
  @include transition($global-transition);
  .nav-search-active & {
    opacity: 1;
    z-index: 90;
  }
  label {
    display: none !important;
  }
  input {
    margin-right: 1rem;
    font-size: 1rem;
    border: 3px solid $secondary-colour;
    @extend %border-radius-1;
    @include transition($global-transition);
    &:focus-visible {
      border-color: $tertiary-colour;
      outline: none !important;
    }
  }
  svg {
    width: 24px;
    cursor: pointer;
    * {
      @include transition($global-transition);
    }
    &:hover {
      * {
        fill: $tertiary-colour;
      }
    }
  }
}

mark {
  background: transparent;
  .search-form & {
    background: $secondary-colour;
    color: $white;
  }
}
.com-finder__pagination {
  .pagination {
    display: flex;
    margin-bottom: 1rem;
    li {
      display: inline-flex;
    }
    a {
      display: inline-block;
      width: 40px;
      margin: 0 10px 0 0 !important;
      padding: 10px 0;
      border-radius: 50%;
      box-sizing: content-box;
      color: $primary-colour;
      cursor: pointer;
      font-weight: bold;
      line-height: 18px;
      text-align: center;
      &:hover {
        color: $secondary-colour;
      }
    }
  }
}
