@charset "UTF-8";
/* MAIN LESS FILE FOR COMPILING
--------------------------------------------------------------*/
/*--------------------------------------------------------------
1.0 Settings
--------------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
/*--------------------------------------------------------------
2.0 Base
--------------------------------------------------------------*/
/* #Reset & Basics (Inspired by E. Meyers)
================================================== */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  position: relative;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased; /* Fix for webkit rendering */
}

body {
  margin: 0;
}

.sr {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1rem;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  padding: 0.75rem;
  border: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

/* Balbooa Forms Component 
================================================== */
.com-baforms-wrapper .ba-form-page .ba-form-field-item {
  margin: 0 0 1rem 0;
}
.com-baforms-wrapper .ba-form-page fieldset {
  padding: 0;
  border: none;
}
.com-baforms-wrapper .ba-form-page .ba-form-field-item .ba-form-products-cart .ba-form-product-quantity-cell input,
.com-baforms-wrapper .ba-form-page .ba-form-page-navigation-title,
.com-baforms-wrapper .ba-form-page .upload-file-btn,
.com-baforms-wrapper .ba-form-page .upload-file-input,
.com-baforms-wrapper .ba-form-page .ba-field-container select,
.com-baforms-wrapper .ba-form-page .ba-field-container textarea[readonly],
.com-baforms-wrapper .ba-form-page .ba-field-container textarea,
.com-baforms-wrapper .ba-form-page .ba-field-container input[type=text],
.com-baforms-wrapper .ba-form-page .ba-field-container input[type=number],
.com-baforms-wrapper .ba-form-page .ba-field-container input[type=password],
.com-baforms-wrapper .ba-form-page .ba-field-container input[type=email],
.com-baforms-wrapper .ba-form-page .ba-field-container select option,
.com-baforms-wrapper .ba-form-page .ba-form-field-item .ba-form-checkbox-wrapper .ba-checkbox-title {
  font-weight: normal;
  font-size: 1rem !important;
  color: #0a0a0a !important;
}
.com-baforms-wrapper .ba-form-page .ba-form-acceptance-field .ba-field-container {
  margin-top: 2rem;
}
.com-baforms-wrapper .ba-form-page .ba-form-submit-wrapper .ba-form-submit-btn-wrapper {
  width: auto !important;
}
.com-baforms-wrapper .ba-form-page .ba-form-submit-field {
  margin-bottom: 0 !important;
}
.com-baforms-wrapper .ba-form-page .ba-form-submit-btn {
  margin-top: 2rem;
  margin-bottom: 0;
}

/* Search
================================================== */
.com-finder__search {
  padding: 0;
}

.com-finder__search-legend {
  margin: 1.5rem 0 2rem;
  font-size: 3rem;
}

#search-result-list mark,
.awesomplete mark {
  background-color: #5341f0;
  color: #ffffff;
}

.table-wrap {
  width: 100%;
  overflow-x: auto;
}

table {
  text-align: left;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  margin: 0;
  border: 1px solid #ffffff;
}
table td,
table th {
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
}
table td *,
table th * {
  margin: 0;
}
table th {
  background-color: #5341f0;
  font-size: 1.1rem;
}
table th * {
  color: #ffffff;
}

table tr:nth-child(2n) {
  background-color: #edf0ef;
}

img {
  max-width: 100%;
  height: auto;
}

body figure[data-wf-figure] {
  display: block;
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.embed-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

body {
  font-family: "Work Sans", sans-serif;
  font-size: 100%;
  line-height: 1.5;
  color: #0a0a0a;
  font-weight: 400;
  background: #fafafa;
}

main,
details {
  display: block;
}

a {
  color: #5341f0;
  background-color: transparent;
  text-decoration: none;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
a:hover {
  color: #0a0a0a;
}

b,
.b,
strong,
.strong {
  font-weight: 500;
}

em,
.em {
  font-style: italic;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

small,
.small {
  font-size: 0.8125rem;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

template,
[hidden] {
  display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Work Sans", sans-serif;
  line-height: 1.2;
  font-weight: 500;
  margin: 1.5rem 0 2rem;
}

h1,
.h1 {
  font-size: 3rem;
}

h2,
.h2 {
  font-size: 2.6rem;
}

h3,
.h3 {
  font-size: 2.2rem;
}

h4,
.h4 {
  font-size: 1.8rem;
}

h5,
.h5 {
  font-size: 1.6rem;
}

h6,
.h6 {
  font-size: 1.2rem;
}

.title {
  text-transform: uppercase;
}

.button,
button,
input[type=submit],
input[type=reset],
input[type=button],
.com-baforms-wrapper .ba-form-submit-btn,
#n2-ss-2 .n2-ss-button-container .button {
  position: relative;
  overflow: hidden;
  display: inline-block;
  margin-bottom: 1.5rem;
  padding: 1.25rem 1.5rem !important;
  background: #5341f0;
  border: 0;
  color: #ffffff !important;
  font-size: 0.9rem !important;
  font-weight: normal;
  text-decoration: none;
  line-height: normal;
  font-family: "Work Sans", sans-serif;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  outline: none;
  border-radius: 8px;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  -webkit-appearance: none;
}
.button *,
button *,
input[type=submit] *,
input[type=reset] *,
input[type=button] *,
.com-baforms-wrapper .ba-form-submit-btn *,
#n2-ss-2 .n2-ss-button-container .button * {
  color: #ffffff;
}
.button .ripple,
button .ripple,
input[type=submit] .ripple,
input[type=reset] .ripple,
input[type=button] .ripple,
.com-baforms-wrapper .ba-form-submit-btn .ripple,
#n2-ss-2 .n2-ss-button-container .button .ripple {
  position: absolute;
  background: #ffffff;
  transform: translate(-50%, -50%);
  pointer-events: none;
  border-radius: 50%;
  animation: ripple 1s ease-out infinite;
}
@keyframes ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 0.5;
  }
  100% {
    width: 500px;
    height: 500px;
    opacity: 0;
  }
}
.button:hover,
button:hover,
input[type=submit]:hover,
input[type=reset]:hover,
input[type=button]:hover,
.com-baforms-wrapper .ba-form-submit-btn:hover,
#n2-ss-2 .n2-ss-button-container .button:hover {
  color: #ffffff;
  border: 0;
}
.button:active,
button:active,
input[type=submit]:active,
input[type=reset]:active,
input[type=button]:active,
.com-baforms-wrapper .ba-form-submit-btn:active,
#n2-ss-2 .n2-ss-button-container .button:active {
  border: 0;
}
.button:visited,
button:visited,
input[type=submit]:visited,
input[type=reset]:visited,
input[type=button]:visited,
.com-baforms-wrapper .ba-form-submit-btn:visited,
#n2-ss-2 .n2-ss-button-container .button:visited {
  color: #ffffff;
}

.button--full-width {
  width: 100%;
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-align: center;
}
.button--secondary {
  background: #61e387;
}
.button--black {
  background: #141747;
}
.button--white {
  background: #ffffff;
  color: #141747 !important;
}
.button--white .ripple {
  background-color: #5341f0;
}
.button-wrap--center {
  text-align: center;
}
.button--margin {
  margin: 0.5rem;
}

/* Fix for odd Mozilla border & padding issues */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/* Onstrust Popup
================================================== */
#onetrust-banner-sdk {
  outline: none !important;
}
#onetrust-banner-sdk button {
  background: #5341f0 !important;
  font-family: "Work Sans", sans-serif !important;
  font-weight: normal !important;
  text-decoration: none !important;
  border-radius: 8px !important;
  outline: none !important;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
#onetrust-banner-sdk button *:hover {
  border: 0 !important;
}
#onetrust-banner-sdk button#onetrust-pc-btn-handler.cookie-setting-link {
  background-color: #141747 !important;
}

.padding-left-0 {
  padding-left: 0;
}

.padding-left-0-5rem {
  padding-left: 0.5rem;
}

.padding-left-1rem {
  padding-left: 1rem;
}

.padding-left-1-5rem {
  padding-left: 1.5rem;
}

.padding-left-2rem {
  padding-left: 2rem;
}

.padding-left-3rem {
  padding-left: 3rem;
}

.padding-left-4rem {
  padding-left: 4rem;
}

.padding-left-5rem {
  padding-left: 5rem;
}

.padding-right-0 {
  padding-right: 0;
}

.padding-right-0-25rem {
  padding-right: 0.25rem;
}

.padding-right-0-5rem {
  padding-right: 0.5rem;
}

.padding-right-1rem {
  padding-right: 1rem;
}

.padding-right-1-5rem {
  padding-right: 1.5rem;
}

.padding-right-2rem {
  padding-right: 2rem;
}

.padding-right-3rem {
  padding-right: 3rem;
}

.padding-right-4rem {
  padding-right: 4rem;
}

.padding-right-5rem {
  padding-right: 5rem;
}

.margin-left-0 {
  margin-left: 0;
}

.margin-left-0-25rem {
  margin-left: 0.25rem;
}

.margin-left-0-5rem {
  margin-left: 0.5rem;
}

.margin-left-1rem {
  margin-left: 1rem;
}

.margin-left-1-5rem {
  margin-left: 1.5rem;
}

.margin-left-2rem {
  margin-left: 2rem;
}

.margin-left-3rem {
  margin-left: 3rem;
}

.margin-left-4rem {
  margin-left: 4rem;
}

.margin-left-5rem {
  margin-left: 5rem;
}

.margin-right-0 {
  margin-right: 0;
}

.margin-right-0-25rem {
  margin-right: 0.25rem;
}

.margin-right-0-5rem {
  margin-right: 0.5rem;
}

.margin-right-1rem {
  margin-right: 1rem;
}

.margin-right-1-5rem {
  margin-right: 1.5rem;
}

.margin-right-2rem {
  margin-right: 2rem;
}

.margin-right-3rem {
  margin-right: 3rem;
}

.margin-right-4rem {
  margin-right: 4rem;
}

.margin-right-5rem {
  margin-right: 5rem;
}

.padding-top-0 {
  padding-top: 0;
}

.padding-top-0-25rem {
  padding-top: 0.25rem;
}

.padding-top-0-5rem {
  padding-top: 0.5rem;
}

.padding-top-1rem {
  padding-top: 1rem;
}

.padding-top-1-5rem {
  padding-top: 1.5rem;
}

.padding-top-2rem {
  padding-top: 2rem;
}

.padding-top-3rem {
  padding-top: 3rem;
}

.padding-top-4rem {
  padding-top: 4rem;
}

.padding-top-5rem {
  padding-top: 5rem;
}

.padding-bottom-0 {
  padding-bottom: 0;
}

.padding-bottom-0-25rem {
  padding-bottom: 0.25rem;
}

.padding-bottom-0-5rem {
  padding-bottom: 0.5rem;
}

.padding-bottom-1rem {
  padding-bottom: 1rem;
}

.padding-bottom-1-5rem {
  padding-bottom: 1.5rem;
}

.padding-bottom-2rem {
  padding-bottom: 2rem;
}

.padding-bottom-3rem {
  padding-bottom: 3rem;
}

.padding-bottom-4rem {
  padding-bottom: 4rem;
}

.padding-bottom-5rem {
  padding-bottom: 5rem;
}

.margin-top-0 {
  margin-top: 0;
}

.margin-top-0-25rem {
  margin-top: 0.25rem;
}

.margin-top-0-5rem {
  margin-top: 0.5rem;
}

.margin-top-1rem {
  margin-top: 1rem;
}

.margin-top-1-5rem {
  margin-top: 1.5rem;
}

.margin-top-2rem {
  margin-top: 2rem;
}

.margin-top-3rem {
  margin-top: 3rem;
}

.margin-top-4rem {
  margin-top: 4rem;
}

.margin-top-5rem {
  margin-top: 5rem;
}

.margin-bottom-0 {
  margin-bottom: 0;
}

.margin-bottom-0-25rem {
  margin-bottom: 0.25rem;
}

.margin-bottom-0-5rem {
  margin-bottom: 0.5rem;
}

.margin-bottom-1rem {
  margin-bottom: 1rem;
}

.margin-bottom-1-5rem {
  margin-bottom: 1.5rem;
}

.margin-bottom-2rem {
  margin-bottom: 2rem;
}

.margin-bottom-3rem {
  margin-bottom: 3rem;
}

.margin-bottom-4rem {
  margin-bottom: 4rem;
}

.margin-bottom-5rem {
  margin-bottom: 5rem;
}

.padding-0 {
  padding: 0;
}

.padding-0-25rem {
  padding: 0.25rem;
}

.padding-0-5rem {
  padding: 0.5rem;
}

.padding-1rem {
  padding: 1rem;
}

.padding-1-5rem {
  padding: 1.5rem;
}

.padding-2rem {
  padding: 2rem;
}

.padding-3rem {
  padding: 3rem;
}

.padding-4rem {
  padding: 4rem;
}

.padding-5rem {
  padding: 5rem;
}

.margin-0 {
  margin: 0;
}

.margin-0-25rem {
  margin: 0.25rem;
}

.margin-0-5rem {
  margin: 0.5rem;
}

.margin-1rem {
  margin: 1rem;
}

.margin-1-5rem {
  margin: 1.5rem;
}

.margin-2rem {
  margin: 2rem;
}

.margin-3rem {
  margin: 3rem;
}

.margin-4rem {
  margin: 4rem;
}

.margin-5rem {
  margin: 5rem;
}

.none {
  display: none;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-align-items-top {
  align-items: flex-start;
}

.flex-align-items-center {
  align-items: center;
}

.flex-align-items-bottom {
  align-items: flex-end;
}

.flex-justify-left {
  justify-content: flex-start;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-right {
  justify-content: flex-end;
}

.flex-space-around {
  justify-content: space-around;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column {
  flex-direction: column;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.sticky {
  position: sticky;
}

.no-select {
  user-select: none;
  cursor: default;
}

.font-100 {
  font-weight: 100;
}

.font-200 {
  font-weight: 200;
}

.font-300 {
  font-weight: 300;
}

.font-400 {
  font-weight: 400;
}

.font-500 {
  font-weight: 500;
}

.font-600 {
  font-weight: 600;
}

.font-700 {
  font-weight: 700;
}

.font-800 {
  font-weight: 800;
}

.font-900 {
  font-weight: 900;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

/*--------------------------------------------------------------
3.0 Foundation
--------------------------------------------------------------*/
/**
 * Foundation for Sites
 * Version 6.8.1
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/* either include all foundation components…  //*/
/* or include specific foundation components //*/
/* include either foundation-grid… //*/
/* or foundation-flex-grid (but don't include both) //*/
.row {
  max-width: 1600px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
}
.row .row {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}
@media print, screen and (min-width: 30em) {
  .row .row {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
  }
}
@media print, screen and (min-width: 36em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media print, screen and (min-width: 48em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media print, screen and (min-width: 60em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media screen and (min-width: 80em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media screen and (min-width: 100em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media screen and (min-width: 100em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
.row .row.collapse {
  margin-right: 0;
  margin-left: 0;
}
.row.expanded {
  max-width: none;
}
.row.expanded .row {
  margin-right: auto;
  margin-left: auto;
}
.row:not(.expanded) .row {
  max-width: none;
}
.row.collapse > .column, .row.collapse > .columns, .newslist .row.collapse > .latestnews-item {
  padding-right: 0;
  padding-left: 0;
}
.row.is-collapse-child, .row.collapse > .column > .row, .row.collapse > .columns > .row, .newslist .row.collapse > .latestnews-item > .row {
  margin-right: 0;
  margin-left: 0;
}

.column, .columns, .newslist .latestnews-item {
  flex: 1 1 0px;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  min-width: 0;
}
@media print, screen and (min-width: 36em) {
  .column, .columns, .newslist .latestnews-item {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.column.row.row, .row.row.columns, .newslist .row.row.latestnews-item {
  float: none;
  display: block;
}

.row .column.row.row, .row .row.row.columns, .row .newslist .row.row.latestnews-item, .newslist .row .row.row.latestnews-item {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;
}

.small-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.small-offset-0 {
  margin-left: 0%;
}

.small-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.small-offset-1 {
  margin-left: 8.3333333333%;
}

.small-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.small-offset-2 {
  margin-left: 16.6666666667%;
}

.small-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.small-offset-3 {
  margin-left: 25%;
}

.small-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.small-offset-4 {
  margin-left: 33.3333333333%;
}

.small-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.small-offset-5 {
  margin-left: 41.6666666667%;
}

.small-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.small-offset-6 {
  margin-left: 50%;
}

.small-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.small-offset-7 {
  margin-left: 58.3333333333%;
}

.small-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.small-offset-8 {
  margin-left: 66.6666666667%;
}

.small-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.small-offset-9 {
  margin-left: 75%;
}

.small-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.small-offset-10 {
  margin-left: 83.3333333333%;
}

.small-12, .newslist .latestnews-item {
  flex: 0 0 100%;
  max-width: 100%;
}

.small-offset-11 {
  margin-left: 91.6666666667%;
}

.small-up-1 {
  flex-wrap: wrap;
}
.small-up-1 > .column, .small-up-1 > .columns, .newslist .small-up-1 > .latestnews-item {
  flex: 0 0 100%;
  max-width: 100%;
}

.small-up-2 {
  flex-wrap: wrap;
}
.small-up-2 > .column, .small-up-2 > .columns, .newslist .small-up-2 > .latestnews-item {
  flex: 0 0 50%;
  max-width: 50%;
}

.small-up-3 {
  flex-wrap: wrap;
}
.small-up-3 > .column, .small-up-3 > .columns, .newslist .small-up-3 > .latestnews-item {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.small-up-4 {
  flex-wrap: wrap;
}
.small-up-4 > .column, .small-up-4 > .columns, .newslist .small-up-4 > .latestnews-item {
  flex: 0 0 25%;
  max-width: 25%;
}

.small-up-5 {
  flex-wrap: wrap;
}
.small-up-5 > .column, .small-up-5 > .columns, .newslist .small-up-5 > .latestnews-item {
  flex: 0 0 20%;
  max-width: 20%;
}

.small-up-6 {
  flex-wrap: wrap;
}
.small-up-6 > .column, .small-up-6 > .columns, .newslist .small-up-6 > .latestnews-item {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.small-up-7 {
  flex-wrap: wrap;
}
.small-up-7 > .column, .small-up-7 > .columns, .newslist .small-up-7 > .latestnews-item {
  flex: 0 0 14.2857142857%;
  max-width: 14.2857142857%;
}

.small-up-8 {
  flex-wrap: wrap;
}
.small-up-8 > .column, .small-up-8 > .columns, .newslist .small-up-8 > .latestnews-item {
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

.small-collapse > .column, .small-collapse > .columns, .newslist .small-collapse > .latestnews-item {
  padding-right: 0;
  padding-left: 0;
}

.small-uncollapse > .column, .small-uncollapse > .columns, .newslist .small-uncollapse > .latestnews-item {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}

@media print, screen and (min-width: 30em) {
  .smedium-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .smedium-offset-0 {
    margin-left: 0%;
  }
  .smedium-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .smedium-offset-1 {
    margin-left: 8.3333333333%;
  }
  .smedium-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .smedium-offset-2 {
    margin-left: 16.6666666667%;
  }
  .smedium-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .smedium-offset-3 {
    margin-left: 25%;
  }
  .smedium-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .smedium-offset-4 {
    margin-left: 33.3333333333%;
  }
  .smedium-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .smedium-offset-5 {
    margin-left: 41.6666666667%;
  }
  .smedium-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .smedium-offset-6 {
    margin-left: 50%;
  }
  .smedium-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .smedium-offset-7 {
    margin-left: 58.3333333333%;
  }
  .smedium-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .smedium-offset-8 {
    margin-left: 66.6666666667%;
  }
  .smedium-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .smedium-offset-9 {
    margin-left: 75%;
  }
  .smedium-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .smedium-offset-10 {
    margin-left: 83.3333333333%;
  }
  .smedium-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .smedium-offset-11 {
    margin-left: 91.6666666667%;
  }
  .smedium-up-1 {
    flex-wrap: wrap;
  }
  .smedium-up-1 > .column, .smedium-up-1 > .columns, .newslist .smedium-up-1 > .latestnews-item {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .smedium-up-2 {
    flex-wrap: wrap;
  }
  .smedium-up-2 > .column, .smedium-up-2 > .columns, .newslist .smedium-up-2 > .latestnews-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .smedium-up-3 {
    flex-wrap: wrap;
  }
  .smedium-up-3 > .column, .smedium-up-3 > .columns, .newslist .smedium-up-3 > .latestnews-item {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .smedium-up-4 {
    flex-wrap: wrap;
  }
  .smedium-up-4 > .column, .smedium-up-4 > .columns, .newslist .smedium-up-4 > .latestnews-item {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .smedium-up-5 {
    flex-wrap: wrap;
  }
  .smedium-up-5 > .column, .smedium-up-5 > .columns, .newslist .smedium-up-5 > .latestnews-item {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .smedium-up-6 {
    flex-wrap: wrap;
  }
  .smedium-up-6 > .column, .smedium-up-6 > .columns, .newslist .smedium-up-6 > .latestnews-item {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .smedium-up-7 {
    flex-wrap: wrap;
  }
  .smedium-up-7 > .column, .smedium-up-7 > .columns, .newslist .smedium-up-7 > .latestnews-item {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .smedium-up-8 {
    flex-wrap: wrap;
  }
  .smedium-up-8 > .column, .smedium-up-8 > .columns, .newslist .smedium-up-8 > .latestnews-item {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media print, screen and (min-width: 30em) and (min-width: 30em) {
  .smedium-expand {
    flex: 1 1 0px;
  }
}
.row.smedium-unstack > .column, .row.smedium-unstack > .columns, .newslist .row.smedium-unstack > .latestnews-item {
  flex: 0 0 100%;
}
@media print, screen and (min-width: 30em) {
  .row.smedium-unstack > .column, .row.smedium-unstack > .columns, .newslist .row.smedium-unstack > .latestnews-item {
    flex: 1 1 0px;
  }
  .smedium-collapse > .column, .smedium-collapse > .columns, .newslist .smedium-collapse > .latestnews-item {
    padding-right: 0;
    padding-left: 0;
  }
  .smedium-uncollapse > .column, .smedium-uncollapse > .columns, .newslist .smedium-uncollapse > .latestnews-item {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }
}

@media print, screen and (min-width: 36em) {
  .medium-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .medium-offset-0 {
    margin-left: 0%;
  }
  .medium-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .medium-offset-1 {
    margin-left: 8.3333333333%;
  }
  .medium-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .medium-offset-2 {
    margin-left: 16.6666666667%;
  }
  .medium-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .medium-offset-3 {
    margin-left: 25%;
  }
  .medium-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .medium-offset-4 {
    margin-left: 33.3333333333%;
  }
  .medium-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .medium-offset-5 {
    margin-left: 41.6666666667%;
  }
  .medium-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .medium-offset-6 {
    margin-left: 50%;
  }
  .medium-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .medium-offset-7 {
    margin-left: 58.3333333333%;
  }
  .medium-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .medium-offset-8 {
    margin-left: 66.6666666667%;
  }
  .medium-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .medium-offset-9 {
    margin-left: 75%;
  }
  .medium-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .medium-offset-10 {
    margin-left: 83.3333333333%;
  }
  .medium-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .medium-offset-11 {
    margin-left: 91.6666666667%;
  }
  .medium-up-1 {
    flex-wrap: wrap;
  }
  .medium-up-1 > .column, .medium-up-1 > .columns, .newslist .medium-up-1 > .latestnews-item {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .medium-up-2 {
    flex-wrap: wrap;
  }
  .medium-up-2 > .column, .medium-up-2 > .columns, .newslist .medium-up-2 > .latestnews-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .medium-up-3 {
    flex-wrap: wrap;
  }
  .medium-up-3 > .column, .medium-up-3 > .columns, .newslist .medium-up-3 > .latestnews-item {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .medium-up-4 {
    flex-wrap: wrap;
  }
  .medium-up-4 > .column, .medium-up-4 > .columns, .newslist .medium-up-4 > .latestnews-item {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .medium-up-5 {
    flex-wrap: wrap;
  }
  .medium-up-5 > .column, .medium-up-5 > .columns, .newslist .medium-up-5 > .latestnews-item {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .medium-up-6 {
    flex-wrap: wrap;
  }
  .medium-up-6 > .column, .medium-up-6 > .columns, .newslist .medium-up-6 > .latestnews-item {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .medium-up-7 {
    flex-wrap: wrap;
  }
  .medium-up-7 > .column, .medium-up-7 > .columns, .newslist .medium-up-7 > .latestnews-item {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .medium-up-8 {
    flex-wrap: wrap;
  }
  .medium-up-8 > .column, .medium-up-8 > .columns, .newslist .medium-up-8 > .latestnews-item {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media print, screen and (min-width: 36em) and (min-width: 36em) {
  .medium-expand {
    flex: 1 1 0px;
  }
}
.row.medium-unstack > .column, .row.medium-unstack > .columns, .newslist .row.medium-unstack > .latestnews-item {
  flex: 0 0 100%;
}
@media print, screen and (min-width: 36em) {
  .row.medium-unstack > .column, .row.medium-unstack > .columns, .newslist .row.medium-unstack > .latestnews-item {
    flex: 1 1 0px;
  }
  .medium-collapse > .column, .medium-collapse > .columns, .newslist .medium-collapse > .latestnews-item {
    padding-right: 0;
    padding-left: 0;
  }
  .medium-uncollapse > .column, .medium-uncollapse > .columns, .newslist .medium-uncollapse > .latestnews-item {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media print, screen and (min-width: 48em) {
  .mlarge-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .mlarge-offset-0 {
    margin-left: 0%;
  }
  .mlarge-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .mlarge-offset-1 {
    margin-left: 8.3333333333%;
  }
  .mlarge-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .mlarge-offset-2 {
    margin-left: 16.6666666667%;
  }
  .mlarge-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .mlarge-offset-3 {
    margin-left: 25%;
  }
  .mlarge-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .mlarge-offset-4 {
    margin-left: 33.3333333333%;
  }
  .mlarge-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .mlarge-offset-5 {
    margin-left: 41.6666666667%;
  }
  .mlarge-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .mlarge-offset-6 {
    margin-left: 50%;
  }
  .mlarge-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .mlarge-offset-7 {
    margin-left: 58.3333333333%;
  }
  .mlarge-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .mlarge-offset-8 {
    margin-left: 66.6666666667%;
  }
  .mlarge-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .mlarge-offset-9 {
    margin-left: 75%;
  }
  .mlarge-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .mlarge-offset-10 {
    margin-left: 83.3333333333%;
  }
  .mlarge-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .mlarge-offset-11 {
    margin-left: 91.6666666667%;
  }
  .mlarge-up-1 {
    flex-wrap: wrap;
  }
  .mlarge-up-1 > .column, .mlarge-up-1 > .columns, .newslist .mlarge-up-1 > .latestnews-item {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .mlarge-up-2 {
    flex-wrap: wrap;
  }
  .mlarge-up-2 > .column, .mlarge-up-2 > .columns, .newslist .mlarge-up-2 > .latestnews-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .mlarge-up-3 {
    flex-wrap: wrap;
  }
  .mlarge-up-3 > .column, .mlarge-up-3 > .columns, .newslist .mlarge-up-3 > .latestnews-item {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .mlarge-up-4 {
    flex-wrap: wrap;
  }
  .mlarge-up-4 > .column, .mlarge-up-4 > .columns, .newslist .mlarge-up-4 > .latestnews-item {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .mlarge-up-5 {
    flex-wrap: wrap;
  }
  .mlarge-up-5 > .column, .mlarge-up-5 > .columns, .newslist .mlarge-up-5 > .latestnews-item {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .mlarge-up-6 {
    flex-wrap: wrap;
  }
  .mlarge-up-6 > .column, .mlarge-up-6 > .columns, .newslist .mlarge-up-6 > .latestnews-item {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .mlarge-up-7 {
    flex-wrap: wrap;
  }
  .mlarge-up-7 > .column, .mlarge-up-7 > .columns, .newslist .mlarge-up-7 > .latestnews-item {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .mlarge-up-8 {
    flex-wrap: wrap;
  }
  .mlarge-up-8 > .column, .mlarge-up-8 > .columns, .newslist .mlarge-up-8 > .latestnews-item {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media print, screen and (min-width: 48em) and (min-width: 48em) {
  .mlarge-expand {
    flex: 1 1 0px;
  }
}
.row.mlarge-unstack > .column, .row.mlarge-unstack > .columns, .newslist .row.mlarge-unstack > .latestnews-item {
  flex: 0 0 100%;
}
@media print, screen and (min-width: 48em) {
  .row.mlarge-unstack > .column, .row.mlarge-unstack > .columns, .newslist .row.mlarge-unstack > .latestnews-item {
    flex: 1 1 0px;
  }
  .mlarge-collapse > .column, .mlarge-collapse > .columns, .newslist .mlarge-collapse > .latestnews-item {
    padding-right: 0;
    padding-left: 0;
  }
  .mlarge-uncollapse > .column, .mlarge-uncollapse > .columns, .newslist .mlarge-uncollapse > .latestnews-item {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media print, screen and (min-width: 60em) {
  .large-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .large-offset-0 {
    margin-left: 0%;
  }
  .large-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .large-offset-1 {
    margin-left: 8.3333333333%;
  }
  .large-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .large-offset-2 {
    margin-left: 16.6666666667%;
  }
  .large-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .large-offset-3 {
    margin-left: 25%;
  }
  .large-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .large-offset-4 {
    margin-left: 33.3333333333%;
  }
  .large-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .large-offset-5 {
    margin-left: 41.6666666667%;
  }
  .large-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .large-offset-6 {
    margin-left: 50%;
  }
  .large-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .large-offset-7 {
    margin-left: 58.3333333333%;
  }
  .large-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .large-offset-8 {
    margin-left: 66.6666666667%;
  }
  .large-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .large-offset-9 {
    margin-left: 75%;
  }
  .large-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .large-offset-10 {
    margin-left: 83.3333333333%;
  }
  .large-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .large-offset-11 {
    margin-left: 91.6666666667%;
  }
  .large-up-1 {
    flex-wrap: wrap;
  }
  .large-up-1 > .column, .large-up-1 > .columns, .newslist .large-up-1 > .latestnews-item {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .large-up-2 {
    flex-wrap: wrap;
  }
  .large-up-2 > .column, .large-up-2 > .columns, .newslist .large-up-2 > .latestnews-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .large-up-3 {
    flex-wrap: wrap;
  }
  .large-up-3 > .column, .large-up-3 > .columns, .newslist .large-up-3 > .latestnews-item {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .large-up-4 {
    flex-wrap: wrap;
  }
  .large-up-4 > .column, .large-up-4 > .columns, .newslist .large-up-4 > .latestnews-item {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .large-up-5 {
    flex-wrap: wrap;
  }
  .large-up-5 > .column, .large-up-5 > .columns, .newslist .large-up-5 > .latestnews-item {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .large-up-6 {
    flex-wrap: wrap;
  }
  .large-up-6 > .column, .large-up-6 > .columns, .newslist .large-up-6 > .latestnews-item {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .large-up-7 {
    flex-wrap: wrap;
  }
  .large-up-7 > .column, .large-up-7 > .columns, .newslist .large-up-7 > .latestnews-item {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .large-up-8 {
    flex-wrap: wrap;
  }
  .large-up-8 > .column, .large-up-8 > .columns, .newslist .large-up-8 > .latestnews-item {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media print, screen and (min-width: 60em) and (min-width: 60em) {
  .large-expand {
    flex: 1 1 0px;
  }
}
.row.large-unstack > .column, .row.large-unstack > .columns, .newslist .row.large-unstack > .latestnews-item {
  flex: 0 0 100%;
}
@media print, screen and (min-width: 60em) {
  .row.large-unstack > .column, .row.large-unstack > .columns, .newslist .row.large-unstack > .latestnews-item {
    flex: 1 1 0px;
  }
  .large-collapse > .column, .large-collapse > .columns, .newslist .large-collapse > .latestnews-item {
    padding-right: 0;
    padding-left: 0;
  }
  .large-uncollapse > .column, .large-uncollapse > .columns, .newslist .large-uncollapse > .latestnews-item {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media screen and (min-width: 80em) {
  .xlarge-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .xlarge-offset-0 {
    margin-left: 0%;
  }
  .xlarge-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xlarge-offset-1 {
    margin-left: 8.3333333333%;
  }
  .xlarge-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xlarge-offset-2 {
    margin-left: 16.6666666667%;
  }
  .xlarge-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xlarge-offset-3 {
    margin-left: 25%;
  }
  .xlarge-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .xlarge-offset-4 {
    margin-left: 33.3333333333%;
  }
  .xlarge-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xlarge-offset-5 {
    margin-left: 41.6666666667%;
  }
  .xlarge-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .xlarge-offset-6 {
    margin-left: 50%;
  }
  .xlarge-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .xlarge-offset-7 {
    margin-left: 58.3333333333%;
  }
  .xlarge-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .xlarge-offset-8 {
    margin-left: 66.6666666667%;
  }
  .xlarge-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .xlarge-offset-9 {
    margin-left: 75%;
  }
  .xlarge-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .xlarge-offset-10 {
    margin-left: 83.3333333333%;
  }
  .xlarge-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xlarge-offset-11 {
    margin-left: 91.6666666667%;
  }
  .xlarge-up-1 {
    flex-wrap: wrap;
  }
  .xlarge-up-1 > .column, .xlarge-up-1 > .columns, .newslist .xlarge-up-1 > .latestnews-item {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xlarge-up-2 {
    flex-wrap: wrap;
  }
  .xlarge-up-2 > .column, .xlarge-up-2 > .columns, .newslist .xlarge-up-2 > .latestnews-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xlarge-up-3 {
    flex-wrap: wrap;
  }
  .xlarge-up-3 > .column, .xlarge-up-3 > .columns, .newslist .xlarge-up-3 > .latestnews-item {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xlarge-up-4 {
    flex-wrap: wrap;
  }
  .xlarge-up-4 > .column, .xlarge-up-4 > .columns, .newslist .xlarge-up-4 > .latestnews-item {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xlarge-up-5 {
    flex-wrap: wrap;
  }
  .xlarge-up-5 > .column, .xlarge-up-5 > .columns, .newslist .xlarge-up-5 > .latestnews-item {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .xlarge-up-6 {
    flex-wrap: wrap;
  }
  .xlarge-up-6 > .column, .xlarge-up-6 > .columns, .newslist .xlarge-up-6 > .latestnews-item {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xlarge-up-7 {
    flex-wrap: wrap;
  }
  .xlarge-up-7 > .column, .xlarge-up-7 > .columns, .newslist .xlarge-up-7 > .latestnews-item {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .xlarge-up-8 {
    flex-wrap: wrap;
  }
  .xlarge-up-8 > .column, .xlarge-up-8 > .columns, .newslist .xlarge-up-8 > .latestnews-item {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media screen and (min-width: 80em) and (min-width: 80em) {
  .xlarge-expand {
    flex: 1 1 0px;
  }
}
.row.xlarge-unstack > .column, .row.xlarge-unstack > .columns, .newslist .row.xlarge-unstack > .latestnews-item {
  flex: 0 0 100%;
}
@media screen and (min-width: 80em) {
  .row.xlarge-unstack > .column, .row.xlarge-unstack > .columns, .newslist .row.xlarge-unstack > .latestnews-item {
    flex: 1 1 0px;
  }
  .xlarge-collapse > .column, .xlarge-collapse > .columns, .newslist .xlarge-collapse > .latestnews-item {
    padding-right: 0;
    padding-left: 0;
  }
  .xlarge-uncollapse > .column, .xlarge-uncollapse > .columns, .newslist .xlarge-uncollapse > .latestnews-item {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media screen and (min-width: 100em) {
  .xxlarge-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .xxlarge-offset-0 {
    margin-left: 0%;
  }
  .xxlarge-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xxlarge-offset-1 {
    margin-left: 8.3333333333%;
  }
  .xxlarge-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xxlarge-offset-2 {
    margin-left: 16.6666666667%;
  }
  .xxlarge-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xxlarge-offset-3 {
    margin-left: 25%;
  }
  .xxlarge-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .xxlarge-offset-4 {
    margin-left: 33.3333333333%;
  }
  .xxlarge-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xxlarge-offset-5 {
    margin-left: 41.6666666667%;
  }
  .xxlarge-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .xxlarge-offset-6 {
    margin-left: 50%;
  }
  .xxlarge-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .xxlarge-offset-7 {
    margin-left: 58.3333333333%;
  }
  .xxlarge-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .xxlarge-offset-8 {
    margin-left: 66.6666666667%;
  }
  .xxlarge-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .xxlarge-offset-9 {
    margin-left: 75%;
  }
  .xxlarge-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .xxlarge-offset-10 {
    margin-left: 83.3333333333%;
  }
  .xxlarge-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xxlarge-offset-11 {
    margin-left: 91.6666666667%;
  }
  .xxlarge-up-1 {
    flex-wrap: wrap;
  }
  .xxlarge-up-1 > .column, .xxlarge-up-1 > .columns, .newslist .xxlarge-up-1 > .latestnews-item {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xxlarge-up-2 {
    flex-wrap: wrap;
  }
  .xxlarge-up-2 > .column, .xxlarge-up-2 > .columns, .newslist .xxlarge-up-2 > .latestnews-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xxlarge-up-3 {
    flex-wrap: wrap;
  }
  .xxlarge-up-3 > .column, .xxlarge-up-3 > .columns, .newslist .xxlarge-up-3 > .latestnews-item {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xxlarge-up-4 {
    flex-wrap: wrap;
  }
  .xxlarge-up-4 > .column, .xxlarge-up-4 > .columns, .newslist .xxlarge-up-4 > .latestnews-item {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xxlarge-up-5 {
    flex-wrap: wrap;
  }
  .xxlarge-up-5 > .column, .xxlarge-up-5 > .columns, .newslist .xxlarge-up-5 > .latestnews-item {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .xxlarge-up-6 {
    flex-wrap: wrap;
  }
  .xxlarge-up-6 > .column, .xxlarge-up-6 > .columns, .newslist .xxlarge-up-6 > .latestnews-item {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xxlarge-up-7 {
    flex-wrap: wrap;
  }
  .xxlarge-up-7 > .column, .xxlarge-up-7 > .columns, .newslist .xxlarge-up-7 > .latestnews-item {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .xxlarge-up-8 {
    flex-wrap: wrap;
  }
  .xxlarge-up-8 > .column, .xxlarge-up-8 > .columns, .newslist .xxlarge-up-8 > .latestnews-item {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media screen and (min-width: 100em) and (min-width: 100em) {
  .xxlarge-expand {
    flex: 1 1 0px;
  }
}
.row.xxlarge-unstack > .column, .row.xxlarge-unstack > .columns, .newslist .row.xxlarge-unstack > .latestnews-item {
  flex: 0 0 100%;
}
@media screen and (min-width: 100em) {
  .row.xxlarge-unstack > .column, .row.xxlarge-unstack > .columns, .newslist .row.xxlarge-unstack > .latestnews-item {
    flex: 1 1 0px;
  }
  .xxlarge-collapse > .column, .xxlarge-collapse > .columns, .newslist .xxlarge-collapse > .latestnews-item {
    padding-right: 0;
    padding-left: 0;
  }
  .xxlarge-uncollapse > .column, .xxlarge-uncollapse > .columns, .newslist .xxlarge-uncollapse > .latestnews-item {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media screen and (min-width: 100em) {
  .xxlarge-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .xxlarge-offset-0 {
    margin-left: 0%;
  }
  .xxlarge-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xxlarge-offset-1 {
    margin-left: 8.3333333333%;
  }
  .xxlarge-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xxlarge-offset-2 {
    margin-left: 16.6666666667%;
  }
  .xxlarge-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xxlarge-offset-3 {
    margin-left: 25%;
  }
  .xxlarge-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .xxlarge-offset-4 {
    margin-left: 33.3333333333%;
  }
  .xxlarge-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xxlarge-offset-5 {
    margin-left: 41.6666666667%;
  }
  .xxlarge-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .xxlarge-offset-6 {
    margin-left: 50%;
  }
  .xxlarge-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .xxlarge-offset-7 {
    margin-left: 58.3333333333%;
  }
  .xxlarge-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .xxlarge-offset-8 {
    margin-left: 66.6666666667%;
  }
  .xxlarge-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .xxlarge-offset-9 {
    margin-left: 75%;
  }
  .xxlarge-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .xxlarge-offset-10 {
    margin-left: 83.3333333333%;
  }
  .xxlarge-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xxlarge-offset-11 {
    margin-left: 91.6666666667%;
  }
  .xxlarge-up-1 {
    flex-wrap: wrap;
  }
  .xxlarge-up-1 > .column, .xxlarge-up-1 > .columns, .newslist .xxlarge-up-1 > .latestnews-item {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xxlarge-up-2 {
    flex-wrap: wrap;
  }
  .xxlarge-up-2 > .column, .xxlarge-up-2 > .columns, .newslist .xxlarge-up-2 > .latestnews-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xxlarge-up-3 {
    flex-wrap: wrap;
  }
  .xxlarge-up-3 > .column, .xxlarge-up-3 > .columns, .newslist .xxlarge-up-3 > .latestnews-item {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xxlarge-up-4 {
    flex-wrap: wrap;
  }
  .xxlarge-up-4 > .column, .xxlarge-up-4 > .columns, .newslist .xxlarge-up-4 > .latestnews-item {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xxlarge-up-5 {
    flex-wrap: wrap;
  }
  .xxlarge-up-5 > .column, .xxlarge-up-5 > .columns, .newslist .xxlarge-up-5 > .latestnews-item {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .xxlarge-up-6 {
    flex-wrap: wrap;
  }
  .xxlarge-up-6 > .column, .xxlarge-up-6 > .columns, .newslist .xxlarge-up-6 > .latestnews-item {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xxlarge-up-7 {
    flex-wrap: wrap;
  }
  .xxlarge-up-7 > .column, .xxlarge-up-7 > .columns, .newslist .xxlarge-up-7 > .latestnews-item {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .xxlarge-up-8 {
    flex-wrap: wrap;
  }
  .xxlarge-up-8 > .column, .xxlarge-up-8 > .columns, .newslist .xxlarge-up-8 > .latestnews-item {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media screen and (min-width: 100em) and (min-width: 100em) {
  .xxlarge-expand {
    flex: 1 1 0px;
  }
}
.row.xxlarge-unstack > .column, .row.xxlarge-unstack > .columns, .newslist .row.xxlarge-unstack > .latestnews-item {
  flex: 0 0 100%;
}
@media screen and (min-width: 100em) {
  .row.xxlarge-unstack > .column, .row.xxlarge-unstack > .columns, .newslist .row.xxlarge-unstack > .latestnews-item {
    flex: 1 1 0px;
  }
  .xxlarge-collapse > .column, .xxlarge-collapse > .columns, .newslist .xxlarge-collapse > .latestnews-item {
    padding-right: 0;
    padding-left: 0;
  }
  .xxlarge-uncollapse > .column, .xxlarge-uncollapse > .columns, .newslist .xxlarge-uncollapse > .latestnews-item {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.shrink {
  flex: 0 0 auto;
  max-width: 100%;
}

.column-block {
  margin-bottom: 1.25rem;
}
.column-block > :last-child {
  margin-bottom: 0;
}
@media print, screen and (min-width: 36em) {
  .column-block {
    margin-bottom: 1.875rem;
  }
  .column-block > :last-child {
    margin-bottom: 0;
  }
}

.accordion {
  margin-left: 0;
  background: #ffffff;
  list-style-type: none;
}
.accordion[disabled] .accordion-title {
  cursor: not-allowed;
}

.accordion-item:first-child > :first-child {
  border-radius: 0 0 0 0;
}
.accordion-item:last-child > :last-child {
  border-radius: 0 0 0 0;
}

.accordion-title {
  position: relative;
  display: block;
  padding: 1.25rem 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  font-size: 0.75rem;
  line-height: 1;
  color: #141747;
}
:last-child:not(.is-active) > .accordion-title {
  border-bottom: 1px solid #e6e6e6;
  border-radius: 0 0 0 0;
}
.accordion-title:hover, .accordion-title:focus {
  background-color: #e6e6e6;
}
.accordion-title::before {
  position: absolute;
  top: 50%;
  right: 1rem;
  margin-top: -0.5rem;
  content: "+";
}
.is-active > .accordion-title::before {
  content: "–";
}

.accordion-content {
  display: none;
  padding: 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  background-color: #ffffff;
  color: #0a0a0a;
}
:last-child > .accordion-content:last-child {
  border-bottom: 1px solid #e6e6e6;
}

/*--------------------------------------------------------------
4.0 Components
--------------------------------------------------------------*/
/* Main
================================================== */
main {
  padding: 6rem 0;
}
@media (min-width: 1280px) {
  main {
    padding-right: 4rem;
    padding-left: 4rem;
  }
}
main ul {
  padding-left: 2rem;
  list-style: circle;
}

.label-warning {
  display: none;
}

.newslist .news, .gallery-slider .n2-ss-slider-1, .bg-graphic--2, .bg-graphic--3, .event-links .newslist .latestnews-item > a, .button,
button,
input[type=submit],
input[type=reset],
input[type=button],
.com-baforms-wrapper .ba-form-submit-btn,
#n2-ss-2 .n2-ss-button-container .button, #onetrust-banner-sdk button {
  -webkit-box-shadow: 8px 8px 40px 0px rgba(33, 33, 33, 0.2);
  -moz-box-shadow: 8px 8px 40px 0px rgba(33, 33, 33, 0.2);
  box-shadow: 8px 8px 40px 0px rgba(33, 33, 33, 0.2);
}

.newslist .news, .gallery-slider .n2-ss-slider-1, .testimonials-slider__logo, .section--gallery-testimonials .row .column::after, .section--gallery-testimonials .row .columns::after, .section--gallery-testimonials .row .newslist .latestnews-item::after, .newslist .section--gallery-testimonials .row .latestnews-item::after, .bg-graphic--2, .bg-graphic--3, .bg-graphic .bg-graphic__wrap, .bg-colour, #nav-search input, .nav a, .event-links .newslist .latestnews-item > a, .slider-gallery-small .n2-ss-slider-1, .box-background .wf-column, .box-links .wf-column.box-link a, .box-links .wf-column, .cards figure, .cards .wf-column, .border-radius {
  overflow: hidden;
  border-radius: 8px;
}

.gallery-slider .nextend-thumbnail-button {
  overflow: hidden;
  border-radius: 4px;
}

.strap,
.strap p {
  margin-bottom: -1rem;
  text-transform: uppercase;
  color: #5341f0;
}
.strap span,
.strap p span {
  display: inline-block;
  margin: 0 1rem;
}
.strap.white,
.strap p.white {
  color: #ffffff;
}

.sponsors-section h6, .subheading {
  position: relative;
  display: inline-flex;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 1rem;
}
.sponsors-section h6:after, .subheading:after {
  content: "";
  position: absolute;
  top: 150%;
  width: 80%;
  height: 4px;
  background-color: #5341f0;
}

.secondary-colour {
  color: #5341f0 !important;
  fill: #5341f0 !important;
}

.tertiary-colour {
  color: #61e387 !important;
  fill: #61e387 !important;
}

/* Components
================================================== */
/* Social
================================================== */
.social ul {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
}
.social ul li {
  width: auto;
  margin-bottom: 0;
}
.social ul li svg {
  width: 1.4em;
  height: 1.4em;
}
.social ul li svg path {
  fill: #5341f0;
}
.social ul li svg * {
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
.social ul li svg:hover path {
  fill: #61e387;
}

/* Sidebar
================================================== */
.sidebar {
  position: relative;
  /*aside {
    position: relative;
    overflow: hidden;
    padding: 3rem;
    text-align: center;
    @extend %border-radius-1;
    @extend %box-shadow;
    background-color: $white;
  }
  &__graphic {
    &--1 {
      .sidebar__content {
        position: relative;
        z-index: 20;
      }
      svg {
        position: absolute;
        z-index: 10;
        top: -320px;
        left: -375px;
      }
    }
  }*/
}
@media (max-width: 767px) {
  .sidebar {
    margin-top: 3rem;
  }
}
.sidebar .moduletable {
  margin-bottom: 3rem;
}
.sidebar--opening-times h6 {
  margin-bottom: 0.25rem;
  font-size: 1rem;
}
.sidebar--opening-times h5 {
  margin-top: 0;
}
.sidebar--opening-times .button {
  margin: 0;
}

/* Cards
================================================== */
.cards {
  gap: 3rem;
  margin-bottom: 3rem;
}
.cards .wf-column {
  position: relative;
  padding: 2rem 2rem 7rem 2rem;
  background-color: #ffffff;
}
.cards figure {
  overflow: hidden;
  width: calc(100% + 4rem);
  height: 320px !important;
  margin-top: -2rem !important;
  margin-left: -2rem !important;
  padding-bottom: 0.5rem;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.cards figure img {
  width: 100% !important;
  height: 100% !important;
  object-position: center !important;
  object-fit: cover !important;
}
.cards .button {
  position: absolute;
  bottom: 2.5rem;
  left: 2rem;
  margin: 0;
}
.cards--hover h1,
.cards--hover h2,
.cards--hover h3,
.cards--hover h4,
.cards--hover h5,
.cards--hover h6,
.cards--hover article {
  transition: 0.5s ease-in-out;
}
.cards--hover figure {
  position: absolute !important;
  inset: 0;
  height: auto !important;
  margin: 0 !important;
  padding: 0 !important;
}
.cards--hover article {
  padding: 0 2rem 7rem;
  background: #141747;
  transform: translateY(100%);
}
.cards--hover article * {
  color: #ffffff;
}
.cards--hover h1,
.cards--hover h2,
.cards--hover h3,
.cards--hover h4,
.cards--hover h5,
.cards--hover h6 {
  position: relative;
  left: -2rem;
  width: calc(100% + 4rem);
  margin: 0;
  padding: 2rem;
  background: #141747;
  border-bottom: 2px solid #141747;
  transform: translateY(-100%);
}
.cards--hover h1:before,
.cards--hover h2:before,
.cards--hover h3:before,
.cards--hover h4:before,
.cards--hover h5:before,
.cards--hover h6:before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
  transform: translateX(-50%);
  opacity: 0.5;
}
.cards--hover .wf-column {
  display: flex;
  overflow: hidden;
  padding: 0;
}
.cards--hover .wf-column:hover h1,
.cards--hover .wf-column:hover h2,
.cards--hover .wf-column:hover h3,
.cards--hover .wf-column:hover h4,
.cards--hover .wf-column:hover h5,
.cards--hover .wf-column:hover h6,
.cards--hover .wf-column:hover article {
  transform: translateY(0);
}
.cards--four-columns {
  gap: 2rem !important;
}
@media (max-width: 1279px) {
  .cards--four-columns {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    gap: 0 !important;
    justify-content: space-between !important;
  }
}
@media (max-width: 1279px) {
  .cards--four-columns .wf-column {
    flex: initial !important;
    flex-basis: calc(50% - 1rem) !important;
    margin-bottom: 2rem !important;
  }
}
@media (max-width: 767px) {
  .cards--four-columns .wf-column {
    flex-basis: 100% !important;
  }
}

/* Accordion
================================================== */
.accordion {
  padding: 0;
}

.accordion-title h1,
.accordion-title h2,
.accordion-title h3,
.accordion-title h4,
.accordion-title h5,
.accordion-title h6 {
  margin: 0;
}
.accordion-title:before {
  margin-top: -0.75rem;
  font-size: 1.5rem;
}

/* Box Links
================================================== */
/* JCE Fix
.box-links {
  cursor: inherit;
  &--large {
    cursor: inherit;
  }
}*/
.box-links .wf-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  padding: 2rem;
  text-align: center;
  background-color: #141747;
}
.box-links .wf-column * {
  color: #ffffff;
}
.box-links .wf-column h1,
.box-links .wf-column h2,
.box-links .wf-column h3,
.box-links .wf-column h4,
.box-links .wf-column h5,
.box-links .wf-column h6 {
  margin-top: 0;
}
.box-links .wf-column.box-link {
  align-items: stretch;
}
.box-links .wf-column.box-link a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 10%;
  background-color: #5341f0;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
.box-links .wf-column.box-link a:hover {
  background-color: rgb(48.767804878, 27.1853658537, 237.0146341463);
}
.box-links--large .wf-column {
  padding: 0;
}
.box-links--large * {
  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: bold;
}
.box-links--large p {
  margin: 10%;
  max-width: 80%;
}

/* JCE Fix
.box-background {
  cursor: inherit;
  &--1,
  &--2,
  &--3 {
    cursor: inherit;
  }
}*/
.box-background .wf-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  padding: 2rem;
  text-align: center;
  background-color: #141747;
}
.box-background .wf-column * {
  color: #ffffff;
}
.box-background .wf-column h1,
.box-background .wf-column h2,
.box-background .wf-column h3,
.box-background .wf-column h4,
.box-background .wf-column h5,
.box-background .wf-column h6 {
  margin-top: 0;
}
.box-background .wf-column ul {
  list-style-position: inside;
}
.box-background .wf-column .button {
  margin-bottom: 0;
}
.box-background--1 .wf-column {
  background-color: #5341f0;
}
.box-background--2 .wf-column {
  background-color: rgb(62.3419354839, 220.8580645161, 108.6774193548);
}
.box-background--3 .wf-column {
  background-color: #7f81a2;
}
.box-background--4 .wf-column {
  background-color: #0a0a0a;
}

/* Slider Bullet Transition
================================================== */
.n2-bullet {
  transition: all 0.5s ease-in-out !important;
}

/* Slider Gallery
================================================== */
/* Feedback Popup
================================================== */
._hj_feedback_container ._hj-Pbej5__styles__resetStyles input[type=submit],
._hj_feedback_container ._hj-Pbej5__styles__resetStyles input[type=button],
._hj_feedback_container ._hj-Pbej5__styles__resetStyles button {
  padding: 0 !important;
}

/* Cookie Settings
================================================== */
#ot-sdk-btn-floating {
  margin-left: 84px;
  margin-bottom: 1rem;
}
#ot-sdk-btn-floating button {
  display: flex !important;
  padding: inherit !important;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

/* Survey Settings
================================================== */
/*#hj-survey-toggle-1 {
  padding: inherit !important;
}*/
._hj-widget-container button {
  padding: inherit !important;
}

/* Event Links
================================================== */
.event-links .newslist .latestnews-items {
  justify-content: center !important;
}
.event-links .newslist .latestnews-item {
  text-align: center;
  flex: 0 0 100%;
  max-width: 100%;
}
@media (min-width: 768px) {
  .event-links .newslist .latestnews-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
.event-links .newslist .latestnews-item > a {
  display: block;
  text-align: center;
  background-color: #ffffff;
}
.event-links .newslist .latestnews-item > a:hover {
  background-color: #141747;
}
.event-links .newslist .latestnews-item > a:hover * {
  color: #ffffff;
  fill: #ffffff;
}
.event-links .newslist .latestnews-item.id-186 svg {
  max-height: 88px;
}
.event-links .newslist .latestnews-item.id-2 svg {
  max-height: 120px;
}
.event-links .newslist .news {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent !important;
}
.event-links .newslist .newstitle {
  display: none;
}
.event-links .newslist .event-logo {
  width: 100%;
}
.event-links .newslist .newsinfo {
  padding: 3rem !important;
}
.event-links .newslist .newsextra {
  text-align: center;
  font-size: 1rem;
}
.event-links .newslist a,
.event-links .newslist svg {
  display: block;
}
.event-links .newslist svg {
  max-height: 64px;
  width: 100%;
}
.event-links .newslist svg * {
  fill: #141747;
}
.event-links .newslist .detail_jfield_3 {
  display: block;
  margin-bottom: 1.5rem;
  font-size: 1rem;
  text-transform: uppercase;
}
.event-links .newslist .detail_jfield_2 {
  display: block;
  margin-top: 1rem;
  font-size: 1.4rem;
  font-weight: 500;
}
header .event-links .newslist .latestnews-items {
  flex-direction: column !important;
  width: 100%;
  margin-top: 1rem;
}
@media (min-width: 576px) {
  header .event-links .newslist .latestnews-items {
    width: 400px;
  }
}
header .event-links .newslist .latestnews-item {
  flex: 0 0 100% !important;
  max-width: 100% !important;
  padding: 0 0 1rem 0 !important;
}
header .event-links .newslist .latestnews-item > a {
  position: relative;
  overflow: hidden;
  background-color: #5341f0 !important;
}
header .event-links .newslist .latestnews-item > a * {
  color: #ffffff !important;
  fill: #ffffff !important;
}
header .event-links .newslist .latestnews-item > a .ripple {
  position: absolute;
  background: #fff;
  transform: translate(-50%, -50%);
  pointer-events: none;
  border-radius: 50%;
  animation: ripple 1s linear infinite;
}
@keyframes ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 0.5;
  }
  100% {
    width: 500px;
    height: 500px;
    opacity: 0;
  }
}
header .event-links .newslist .newsinfo {
  padding: 1.5rem !important;
}
header .event-links .newslist .newsextra {
  text-align: left;
}
header .event-links .newslist svg {
  max-width: 100%;
  width: auto;
  max-height: 40px;
  text-align: left;
}
header .event-links .newslist .detail_jfield_3 {
  margin-bottom: 0.5rem;
}
header .event-links .newslist .detail_jfield_2 {
  margin-top: 0.75rem;
}

.event-list__wrap {
  display: none;
  position: absolute;
  top: -0.5rem;
  right: 0;
  z-index: 100;
  width: 100%;
  height: 55vh;
  padding: 0.5rem 0.5rem 2rem 0.5rem;
  background-color: #ffffff;
}
@media (min-width: 960px) {
  .event-list__wrap {
    height: 75vh;
    top: 100%;
    right: 0.5rem;
    width: 415px;
    padding: 0;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}
.select-menu-active .event-list__wrap {
  display: block;
}
.event-list__wrap .subheading {
  position: absolute;
  color: #141747;
  top: 1.5rem;
  left: 1.5rem;
}
.event-list__inner {
  position: absolute;
  top: 5rem;
  right: 0;
  overflow-y: scroll;
  height: calc(100% - 5rem);
  padding-bottom: 2rem;
}
@media (min-width: 960px) {
  .event-list__inner {
    top: 0;
    height: 100%;
    overflow-y: visible;
  }
}
.event-list__scroll {
  position: absolute;
  right: 15px;
  left: 0;
  bottom: 0;
  z-index: 90;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 0.8rem;
  background-color: #141747;
  padding: 0.25rem;
}
@media (min-width: 576px) {
  .event-list__scroll {
    width: 400px;
    left: auto;
  }
}
@media (min-width: 960px) {
  .event-list__scroll {
    display: none;
  }
}

.button--select-event-close {
  margin-top: 0.6rem !important;
}
@media (min-width: 960px) {
  .button--select-event-close {
    display: none !important;
  }
}

/* Sidebar Links
================================================== */
.events-sidebar {
  position: fixed;
  left: 100%;
  top: 30%;
  z-index: 90;
  transform-origin: 0% 100%;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg) translateX(-50%);
}
@media (max-width: 1279px) {
  .events-sidebar {
    display: none;
  }
}
.events-sidebar__wrap {
  display: flex;
}
.events-sidebar .event-logo {
  display: inline-flex;
}
.events-sidebar .event-logo--hide {
  display: none !important;
}
.events-sidebar .event-logo a {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 260px;
  height: 64px;
  padding: 0.5rem;
  background: #ffffff;
  border: 3px solid #141747;
}
.events-sidebar .event-logo a:hover {
  background: #141747;
  border-color: #ffffff;
}
.events-sidebar .event-logo a:hover svg * {
  fill: #ffffff;
}
.events-sidebar .event-logo a:hover svg .secondary-colour {
  fill: #5341f0;
}
.events-sidebar .event-logo a:hover svg .tertiary-colour {
  fill: #61e387;
}
.events-sidebar .event-logo a img,
.events-sidebar .event-logo a svg {
  max-height: 30px;
  max-width: 100%;
  max-width: 225px;
  width: 100%;
}
.events-sidebar .event-logo a img *,
.events-sidebar .event-logo a svg * {
  fill: #141747;
}

/* Header
================================================== */
header {
  position: relative;
  z-index: 100;
  top: 0;
  right: 0;
  left: 0;
  padding: 0.5rem 0;
  background-color: #ffffff;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
header .row {
  align-items: center;
}

.header__logos a {
  display: flex;
  align-items: center;
}
.header__logos-text {
  width: 100px;
  margin: 0 0 0 1rem;
  padding: 0;
  text-transform: uppercase;
  color: #141747;
}
#home .header__logos-text {
  color: #ffffff;
}
.header__logos-inner {
  display: none;
  align-items: center;
}
@media (min-width: 768px) {
  .header__logos-inner {
    display: inline-flex;
  }
}
@media (min-width: 960px) {
  .header__logos-inner {
    display: none;
  }
}
@media (min-width: 1280px) {
  .header__logos-inner {
    display: inline-flex;
  }
}
.header__logo {
  margin: 0 1rem;
}
.header__logo svg {
  height: auto;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
.header__logo svg * {
  fill: #141747;
}
.header__logo--1 {
  display: none;
  margin-left: 0;
}
.header__logo--1 svg {
  width: 80px;
}
.header__logo--1 svg * {
  fill: #141747;
}
.header__logo--1 svg .tertiary-colour {
  fill: #61e387 !important;
}
#home .header__logo--2 svg *, #home .header__logo--3 svg * {
  fill: #ffffff;
}
.header__logo--2 svg {
  width: 80px;
}
.header__logo--3 svg {
  width: 135px;
}

/* Navigation
================================================== */
.nav {
  display: none;
  width: auto;
  margin: 0;
  padding: 0;
}
@media (min-width: 768px) {
  .nav {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }
}
.nav li {
  position: relative;
  margin-bottom: 0;
  text-align: center;
  list-style: none;
}
@media (max-width: 959px) {
  .nav li {
    width: auto;
    margin: 2rem;
    text-align: center;
  }
}
.nav a {
  display: block;
  margin: 0;
  padding: 0.5rem 1rem;
  color: #0a0a0a;
  font-size: 1rem;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 1;
  border: 3px solid transparent;
}
.nav a.button {
  margin: 0;
  border: 0;
}
.nav a.button--ticket {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem !important;
  font-weight: bold;
  /*&:before {
    @include bp(mlarge) {
      content: "";
      position: absolute;
      z-index: 9;
      top: 0;
      left: 100%;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 86px 44px 0 0;
      border-color: $secondary-colour transparent transparent transparent;
      @include transition($global-transition);
    }
  }*/
}
@media (min-width: 768px) {
  .nav a.button--ticket {
    border-radius: 0;
    box-shadow: none;
  }
}
.nav a.button--ticket:hover img {
  transform: rotate(10deg);
}
@media (min-width: 768px) {
  .nav a.button--ticket:hover:after {
    border-color: #0a0a0a transparent transparent transparent;
  }
}
.nav a.button--ticket img {
  width: 52px;
  margin-right: 0.75rem;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
.nav a.nav__search {
  position: relative;
  padding-left: 2rem;
}
.nav a.nav__search img {
  position: absolute;
  top: 50%;
  right: 10%;
  left: auto;
  transform: translateY(-50%);
}
@media (min-width: 960px) {
  .nav a.nav__search img {
    right: auto;
    left: 10%;
  }
}
.nav a.disable {
  cursor: default;
  border-color: transparent !important;
}
.nav a.disable:hover {
  color: #ffffff !important;
}
.nav a.disable:after {
  display: none;
}
.nav a.hidden {
  display: none !important;
}
.nav a:hover,
.nav .current > a,
.nav .active > a {
  border-color: #141747;
}
.nav a:hover:after,
.nav .current > a:after,
.nav .active > a:after {
  border-color: #141747;
}
.nav ul {
  display: none;
}
.nav .parent > a {
  position: relative;
  padding-right: 2rem;
}
.nav .parent > a:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 5px 0 5px;
  border-color: #141747 transparent transparent transparent;
  transition: 0.5s ease-in-out;
}
@media (min-width: 960px) {
  .nav .parent:hover .mod-menu__sub {
    display: flex;
  }
}
.nav .mod-menu__sub {
  display: none;
  flex-flow: column nowrap;
  list-style: none;
  width: 100%;
  margin: 0;
  padding: 0;
}
@media (max-width: 959px) {
  .nav .mod-menu__sub.active {
    display: flex;
  }
}
.nav .mod-menu__sub li {
  width: 100%;
  margin: 1rem 0;
}
.nav-active .nav {
  display: flex;
  width: 100%;
  height: 100vh;
  position: absolute;
  margin-left: 0;
  left: 0;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  padding-top: 8em;
}
@media (max-width: 479px) {
  .nav-active .nav {
    padding-top: 5em;
  }
}

/* Primary / Secondary Navigation
================================================== */
.nav--main {
  position: relative;
  font-size: 0.8em;
}
@media (max-width: 959px) {
  .nav--main .nav {
    display: block;
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    height: 0;
    overflow: hidden;
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -ms-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
  }
  .nav--main .nav li {
    display: block;
  }
}
.nav--main .nav .mod-menu__sub {
  padding: 1rem 0 0 0;
  background-color: #ffffff;
}
@media (min-width: 960px) {
  .nav--main .nav .mod-menu__sub {
    width: 200%;
    max-width: 240px;
    padding: 1rem 0;
  }
}
.nav--main .nav .mod-menu__sub li {
  width: calc(100% - 2rem);
  margin: 0.5rem 1rem;
}

/* Primary Navigation
================================================== */
.nav--primary .nav {
  -webkit-transition: background-color 0.5s ease-in-out;
  -moz-transition: background-color 0.5s ease-in-out;
  -ms-transition: background-color 0.5s ease-in-out;
  -o-transition: background-color 0.5s ease-in-out;
  transition: background-color 0.5s ease-in-out;
}
@media (min-width: 768px) {
  .nav--primary .nav {
    justify-content: flex-end;
    align-items: center;
  }
}
.primary-menu-active .nav--primary .nav {
  height: auto;
  background-color: #ffffff;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
}
@media (min-width: 960px) {
  .nav--primary .nav a {
    margin: 0 2rem;
  }
  .nav--primary .nav a:last-of-type {
    margin-right: 0;
  }
}
.nav--primary .nav .mod-menu__sub {
  background-color: #ffffff;
}
@media (min-width: 960px) {
  .nav--primary .nav .mod-menu__sub {
    width: 150%;
  }
}
.nav--primary .nav .mod-menu__sub a {
  margin: 0;
}
.nav--primary .nav .parent > a:after {
  border-color: #141747 transparent transparent;
}
.nav--primary .nav-wrap {
  position: relative;
  z-index: 100;
  padding-right: 0.5rem;
}
@media (max-width: 959px) {
  .nav--primary .nav-wrap {
    display: flex;
    align-items: center;
    position: static;
  }
}
.nav--primary ul {
  text-align: right;
}
@media (min-width: 960px) {
  .nav--primary .parent ul {
    position: absolute;
    top: 100%;
    align-items: flex-start;
    background: #eeeeee;
  }
}

/* Secondary Navigation
================================================== */
.nav--secondary {
  top: 0;
  left: 0;
  right: 0;
  z-index: 90;
  background-color: #ffffff;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-transition: box-shadow 0.5s ease-in-out;
  -moz-transition: box-shadow 0.5s ease-in-out;
  -ms-transition: box-shadow 0.5s ease-in-out;
  -o-transition: box-shadow 0.5s ease-in-out;
  transition: box-shadow 0.5s ease-in-out;
  /*a:hover,
  .current > a,
  .active > a {
    &:after {
      border-color: $tertiary-colour transparent transparent transparent;
    }
  }*/
}
.secondary-menu-active .nav--secondary {
  background-color: #ffffff;
}
.nav--secondary.shrink {
  -webkit-box-shadow: 0 0 10px rgba(20, 23, 71, 0.2);
  -moz-box-shadow: 0 0 10px rgba(20, 23, 71, 0.2);
  box-shadow: 0 0 10px rgba(20, 23, 71, 0.2);
}
@media (min-width: 576px) {
  .nav--secondary.shrink {
    position: fixed;
    background-color: #ffffff;
  }
}
.nav--secondary.shrink .nav,
.nav--secondary.shrink .nav ul {
  background-color: #ffffff;
}
.nav--secondary.shrink-animate .button--ticket img {
  width: 32px;
}
.nav--secondary .column, .nav--secondary .columns, .nav--secondary .newslist .latestnews-item, .newslist .nav--secondary .latestnews-item {
  position: relative;
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 1280px) {
  .nav--secondary .column, .nav--secondary .columns, .nav--secondary .newslist .latestnews-item, .newslist .nav--secondary .latestnews-item {
    margin-right: 4rem;
  }
}
@media (max-width: 959px) {
  .nav--secondary .column, .nav--secondary .columns, .nav--secondary .newslist .latestnews-item, .newslist .nav--secondary .latestnews-item {
    padding: 1rem 0;
  }
}
.nav--secondary .nav {
  -webkit-transition: background-color 0.5s ease-in-out;
  -moz-transition: background-color 0.5s ease-in-out;
  -ms-transition: background-color 0.5s ease-in-out;
  -o-transition: background-color 0.5s ease-in-out;
  transition: background-color 0.5s ease-in-out;
}
@media (max-width: 767px) {
  .nav--secondary .nav {
    background-color: rgba(255, 255, 255, 0.8);
  }
}
.secondary-menu-active .nav--secondary .nav {
  height: auto;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  background-color: #ffffff;
}
.nav--secondary .nav li {
  flex-grow: 0;
}
.nav--secondary a {
  text-align: center;
}
@media (max-width: 959px) {
  .nav--secondary a {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
@media (min-width: 960px) {
  .nav--secondary .parent ul {
    position: absolute;
    top: 100%;
    align-items: flex-start;
  }
}
@media (min-width: 960px) {
  .nav--secondary .alias {
    display: none;
  }
}

/* Hamgurger Menu
================================================== */
.nav-button {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  margin: 0 0 0 auto;
  padding: 0;
  outline: 0;
  cursor: pointer;
}
.nav-button p {
  margin: 0;
  padding: 0 2.5rem 0 0;
  color: #ffffff;
}
.nav-button div {
  position: absolute;
  top: 47%;
  right: 3rem;
  transform: translateY(-50%);
}
.nav-button span,
.nav-button span:before,
.nav-button span:after {
  content: "";
  display: block;
  position: absolute;
  width: 2em;
  height: 4px;
  background: #ffffff;
  border-radius: 1px;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
.nav-button span:before {
  top: -10px;
}
.nav-button span:after {
  top: auto;
  bottom: -10px;
}
.nav-button.active span {
  background: transparent;
}
.nav-button.active span:before, .nav-button.active span:after {
  top: 0;
}
.nav-button.active span:before {
  transform: rotate(45deg);
}
.nav-button.active span:after {
  transform: rotate(-45deg);
}
@media (min-width: 960px) {
  .nav-button {
    display: none;
  }
}

/* Navigation Search
================================================== */
#nav-search {
  display: flex;
  align-items: center;
  position: absolute;
  z-index: -1;
  right: 0;
  top: 0;
  height: 100%;
  padding: 0 0 0 6rem;
  background: #ffffff;
  opacity: 0;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
.nav-search-active #nav-search {
  opacity: 1;
  z-index: 90;
}
#nav-search label {
  display: none !important;
}
#nav-search input {
  margin-right: 1rem;
  font-size: 1rem;
  border: 3px solid #5341f0;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
#nav-search input:focus-visible {
  border-color: #61e387;
  outline: none !important;
}
#nav-search svg {
  width: 24px;
  cursor: pointer;
}
#nav-search svg * {
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
#nav-search svg:hover * {
  fill: #61e387;
}

mark {
  background: transparent;
}
.search-form mark {
  background: #5341f0;
  color: #ffffff;
}

.com-finder__pagination .pagination {
  display: flex;
  margin-bottom: 1rem;
}
.com-finder__pagination .pagination li {
  display: inline-flex;
}
.com-finder__pagination .pagination a {
  display: inline-block;
  width: 40px;
  margin: 0 10px 0 0 !important;
  padding: 10px 0;
  border-radius: 50%;
  box-sizing: content-box;
  color: #141747;
  cursor: pointer;
  font-weight: bold;
  line-height: 18px;
  text-align: center;
}
.com-finder__pagination .pagination a:hover {
  color: #5341f0;
}

/* Banner / Slider
================================================== */
.banner {
  position: relative;
  z-index: 60;
  padding: 0;
  /* Global Banner Components
  ================================================== */
  /* Home Large
  ================================================== */
  /* Event Slider
  ================================================== */
  /* Video Full Banner
  ================================================== */
}
.banner__row {
  position: relative !important;
  z-index: 10 !important;
}
.banner__shape, .banner__shape--2 {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  -webkit-transform: translate(-50%, -50%) !important;
  -ms-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
  align-self: auto !important;
  flex: none !important;
}
.banner__logo {
  display: flex;
  justify-content: flex-start;
  /*margin-bottom: 2rem !important;
  @include bp(mlarge) {
    margin-bottom: 3rem !important;
  }*/
}
.banner__logo * {
  fill: #ffffff !important;
  text-align: left;
}
.banner__logo .secondary-colour {
  fill: #5341f0 !important;
}
.banner__logo svg,
.banner__logo img {
  width: 100%;
  max-width: 80%;
  max-height: 72px;
}
@media (min-width: 768px) {
  .banner__logo svg,
  .banner__logo img {
    max-width: 500px;
  }
}
.banner__logo--large svg,
.banner__logo--large img {
  width: 100%;
  max-width: 100%;
  max-height: 100px;
}
@media (min-width: 768px) {
  .banner__logo--large svg,
  .banner__logo--large img {
    max-width: 500px;
  }
}
.banner__date .n2-ss-item-content {
  padding: 1rem;
  background-color: #141747;
  text-align: right;
}
@media (min-width: 768px) {
  .banner__date .n2-ss-item-content {
    padding: 3rem;
  }
}
.banner__date * {
  color: #ffffff;
}
.banner__date .strap {
  margin-bottom: 0.5rem;
}
.banner__date h3 {
  margin-top: 0;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .banner__date h3 {
    font-size: 1.6rem;
  }
}
.banner__date--black .n2-ss-item-content, .banner__date--white .n2-ss-item-content {
  background-color: transparent;
}
.banner__date--black * {
  color: #141747 !important;
}
.banner__date--white * {
  color: #ffffff !important;
}
.banner__date--standard .n2-ss-item-content {
  padding: 0;
  background-color: transparent;
  text-align: inherit;
}
.banner__button {
  border-radius: 8px !important;
  text-transform: uppercase !important;
}
.banner__button.button--secondary {
  background: #61e387 !important;
}
.banner__button.button--black {
  background: #141747 !important;
}
.banner__button.button--white {
  background: #ffffff;
  color: #141747 !important;
}
.banner__button.button--white .ripple {
  background-color: #5341f0;
}
.banner__arrow-down {
  position: relative;
  overflow: visible;
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  margin: 0;
}
.banner__arrow-down span,
.banner__arrow-down div {
  position: absolute;
  display: block;
  border-radius: 8px;
}
.banner__arrow-down img {
  position: relative;
  z-index: 10;
}
.banner__arrow-down span {
  z-index: 5;
  width: 48px;
  height: 48px;
  border: 3px solid #ffffff !important;
  border-radius: 8px;
  background-color: #141747;
}
.banner__arrow-down div {
  z-index: 0;
  width: 72px;
  height: 72px;
  background-color: #ffffff;
  opacity: 0.5;
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.banner--home-slider {
  /* Smaller Slider
  ================================================== */
}
.banner--home-slider .banner__content .n2-ss-section-main-content {
  padding: 160px 0 40px !important;
}
@media (min-width: 960px) {
  .banner--home-slider .banner__content .n2-ss-section-main-content {
    padding-top: 120px !important;
  }
}
.banner--home-slider .banner__content--2 .n2-ss-section-main-content {
  padding: 160px 0 40px !important;
}
@media (min-width: 960px) {
  .banner--home-slider .banner__content--2 .n2-ss-section-main-content {
    padding-top: 0px !important;
  }
}
.banner--home-slider .banner__content--3 .n2-ss-section-main-content {
  padding: 160px 0 75% !important;
}
@media (min-width: 768px) {
  .banner--home-slider .banner__content--3 .n2-ss-section-main-content {
    padding-bottom: 50% !important;
  }
}
@media (min-width: 960px) {
  .banner--home-slider .banner__content--3 .n2-ss-section-main-content {
    padding: 160px 0 0 0 !important;
  }
}
.banner--home-slider .banner__content--3 .n2-ss-layer-row {
  height: 100%;
}
@media (max-width: 959px) {
  .banner--home-slider .banner__content--3 .banner__left {
    width: 100% !important;
  }
}
.banner--home-slider .banner__row > .n2-ss-layer-row > .n2-ss-layer-row-inner {
  flex-wrap: nowrap !important;
}
@media (max-width: 767px) {
  .banner--home-slider .banner__row > .n2-ss-layer-row > .n2-ss-layer-row-inner {
    flex-wrap: wrap !important;
  }
}
.banner--home-slider .banner__title p {
  font-size: 3rem !important;
}
@media (min-width: 768px) {
  .banner--home-slider .banner__title p {
    font-size: 3.5rem !important;
  }
}
.banner--home-slider .banner__right {
  position: relative !important;
}
.banner--home-slider .banner__shape {
  z-index: 20 !important;
}
.banner--home-slider .banner__shape--1 {
  z-index: 22 !important;
  margin-left: -42%;
  margin-top: 26%;
}
@media (min-width: 768px) {
  .banner--home-slider .banner__shape--1 {
    margin-left: -43%;
    margin-top: 52%;
  }
}
@media (min-width: 960px) {
  .banner--home-slider .banner__shape--1 {
    margin-left: -52%;
    margin-top: 39%;
  }
}
.banner--home-slider .banner__shape--2 {
  margin-left: -43%;
  margin-top: 21%;
}
@media (min-width: 768px) {
  .banner--home-slider .banner__shape--2 {
    margin-left: -55%;
    margin-top: 58%;
  }
}
@media (min-width: 960px) {
  .banner--home-slider .banner__shape--2 {
    margin-left: -57%;
    margin-top: 36%;
  }
}
.banner--home-slider .banner__shape--3 {
  margin-left: 33%;
  margin-top: 4%;
}
@media (min-width: 768px) {
  .banner--home-slider .banner__shape--3 {
    margin-left: 38%;
    margin-top: 34%;
  }
}
@media (min-width: 960px) {
  .banner--home-slider .banner__shape--3 {
    margin-left: 27%;
    margin-top: 24%;
  }
}
.banner--home-slider .banner__shape--4 {
  z-index: 24 !important;
  margin-left: 40.5%;
  margin-top: -14%;
}
@media (min-width: 768px) {
  .banner--home-slider .banner__shape--4 {
    margin-left: -43%;
    margin-top: 52%;
  }
}
@media (min-width: 960px) {
  .banner--home-slider .banner__shape--4 {
    margin-left: 46%;
    margin-top: -16%;
  }
}
.banner--home-slider .banner__shape--5 {
  z-index: 25 !important;
  margin-left: 21%;
  margin-top: 30%;
}
@media (min-width: 768px) {
  .banner--home-slider .banner__shape--5 {
    margin-left: 20%;
    margin-top: 74%;
  }
}
@media (min-width: 960px) {
  .banner--home-slider .banner__shape--5 {
    margin-left: 13%;
    margin-top: 56%;
  }
}
.banner--home-slider--small .banner__content .n2-ss-section-main-content {
  padding: 120px 0 0 !important;
}
.banner--home-slider--small .banner__row {
  margin-right: auto !important;
}
@media (min-width: 960px) {
  .banner--home-slider--small .banner__row {
    max-width: 80% !important;
  }
}
@media (min-width: 1600px) {
  .banner--home-slider--small .banner__row {
    max-width: 60% !important;
  }
}
.banner--home-slider--small .banner__title p {
  font-size: 2.4rem !important;
}
@media (min-width: 768px) {
  .banner--home-slider--small .banner__title p {
    font-size: 3rem !important;
  }
}
@media (max-width: 959px) {
  .banner--home-slider-v2 .n2-ss-slide {
    height: auto !important;
  }
}
.banner__video-outer-row {
  width: 80% !important;
  height: 100% !important;
  right: 0 !important;
  left: auto !important;
  transform: none !important;
}
@media (max-width: 959px) {
  .banner__video-outer-row {
    height: 40% !important;
    width: 100% !important;
    bottom: 0 !important;
    top: auto !important;
  }
}
@media (max-width: 767px) {
  .banner__video-outer-row {
    width: 130% !important;
  }
}
.banner__video-mask {
  position: relative !important;
  top: 50% !important;
  left: 50% !important;
  -webkit-transform: translate(-50%, -50%) !important;
  -ms-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
  z-index: 10 !important;
  display: inline-block;
  height: auto !important;
  margin-left: 20% !important;
  -webkit-mask-image: url("../images/shape-1.svg");
  mask-image: url("../images/shape-1.svg");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}
@media (min-width: 960px) {
  .banner__video-mask {
    height: 800px !important;
  }
}
@media (min-width: 1280px) {
  .banner__video-mask {
    margin-left: 0 !important;
  }
}
.banner__video-mask--2 {
  top: 53% !important;
  -webkit-mask-image: url("../images/double-splash-graphic.svg");
  mask-image: url("../images/double-splash-graphic.svg");
  -webkit-mask-size: 70%;
  mask-size: 70%;
}
.banner__video-mask--3 {
  -webkit-mask-image: url("../images/new-video-mask.svg");
  mask-image: url("../images/new-video-mask.svg");
  height: 100% !important;
}
.banner__video-overlay-image--1 {
  height: 82% !important;
  left: 0% !important;
  top: 24% !important;
  transform: none !important;
}
.banner__video-overlay-image--1 .n2-ss-item-image-content,
.banner__video-overlay-image--1 img {
  height: 100% !important;
}
.banner__video-overlay-image--2 {
  height: 100% !important;
  right: -25% !important;
  transform: none !important;
  left: auto !important;
}
.banner__video-overlay-image--2 .n2-ss-item-image-content,
.banner__video-overlay-image--2 img {
  height: 100% !important;
}
.banner--event-slider .banner__content .n2-ss-section-main-content {
  padding: 40px 0 !important;
}
@media (min-width: 960px) {
  .banner--event-slider .banner__content .n2-ss-section-main-content {
    padding: 80px 0 !important;
  }
}
.banner--event-slider .banner__title p {
  font-size: 1.6rem !important;
}
@media (min-width: 768px) {
  .banner--event-slider .banner__title p {
    font-size: 2rem !important;
  }
}
@media (min-width: 960px) {
  .banner--event-slider .banner__title p {
    font-size: 2.6rem !important;
  }
}
.banner--event-slider .banner__title--small p {
  font-size: 1.2rem !important;
}
@media (min-width: 768px) {
  .banner--event-slider .banner__title--small p {
    font-size: 1.4rem !important;
  }
}
@media (min-width: 960px) {
  .banner--event-slider .banner__title--small p {
    font-size: 1.6rem !important;
  }
}
@media (max-width: 767px) {
  .banner--event-slider .banner__content-wrap {
    display: none !important;
  }
}
.banner--event-slider .banner__shape {
  margin-left: 0%;
  margin-top: 8%;
}
@media (min-width: 768px) {
  .banner--event-slider .banner__shape {
    margin-top: 24%;
  }
}
@media (min-width: 960px) {
  .banner--event-slider .banner__shape {
    margin-top: 48%;
  }
}
.banner--event-slider .banner__shape--2 {
  margin-left: 0%;
  margin-top: 15%;
}
@media (min-width: 768px) {
  .banner--event-slider .banner__shape--2 {
    margin-top: 0%;
  }
}
.banner--event-slider .banner__shape--6, .banner--event-slider .banner__shape--7 {
  width: 80% !important;
  height: 100% !important;
  right: 0;
  left: auto !important;
  transform: none !important;
}
.banner--event-slider .banner__shape--6 .n2-ss-item-image-content,
.banner--event-slider .banner__shape--6 img, .banner--event-slider .banner__shape--7 .n2-ss-item-image-content,
.banner--event-slider .banner__shape--7 img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  object-position: left;
}
.banner--event-slider .banner__shape--6 .n2-ss-item-image-content, .banner--event-slider .banner__shape--7 .n2-ss-item-image-content {
  text-align: right;
}
@media (min-width: 576px) {
  .banner--event-slider .banner__shape--7 {
    width: 65% !important;
  }
}
.banner--event-slider .banner__sponsor-title {
  width: 170px !important;
  min-width: 170px !important;
}
.banner--larger-logo .banner__logo img,
.banner--larger-logo .banner__logo svg {
  max-height: 120px;
}
.banner--video-full .n2-ss-slider .n2-ss-slide-background-video {
  object-position: 50% 25%;
}

/* Arrow Animation
================================================== */
@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.75, 0.75, 0.75);
    transform: scale3d(0.75, 0.75, 0.75);
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}
@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.75, 0.75, 0.75);
    transform: scale3d(0.75, 0.75, 0.75);
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}
/* Main Pages
================================================== */
#home header {
  position: absolute;
  background-color: transparent;
}
@media (min-width: 960px) {
  #home header {
    position: fixed;
    padding: 2rem 0;
  }
}
@media (min-width: 960px) {
  #home header.shrink {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background-color: #141747;
  }
  #home header.shrink .header__logo--1 svg {
    width: 80px;
  }
}
#home header .header__logo--1 svg {
  width: 120px;
}
#home header .header__logo--1 svg * {
  fill: #ffffff;
}
#home.primary-menu-active .nav {
  background-color: #141747;
}
#home .nav--primary a {
  color: #ffffff;
}
#home .nav--primary a:hover,
#home .nav--primary .current > a,
#home .nav--primary .active > a {
  border-color: #ffffff;
}
#home .nav--primary .mod-menu__sub {
  background-color: #141747;
}
#home .nav--primary .parent > a:after {
  border-color: #ffffff transparent transparent;
}
#home .event-list__wrap {
  background-color: #141747;
}
@media (min-width: 960px) {
  #home .event-list__wrap {
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}
#home .event-list__wrap .subheading {
  color: #ffffff;
}

/* Sections 
================================================== */
section {
  position: relative;
  overflow: hidden;
  padding: 3rem 0;
}

.bg-colour {
  padding: 3rem;
}
.bg-colour * {
  color: #ffffff;
}
.bg-colour--1 {
  background-color: #5341f0;
}
.bg-colour--center {
  text-align: center;
}

.bg-graphic .bg-graphic__wrap {
  position: relative;
  overflow: hidden;
  padding: 3rem;
}
.bg-graphic .bg-graphic__wrap h1,
.bg-graphic .bg-graphic__wrap h2,
.bg-graphic .bg-graphic__wrap h3,
.bg-graphic .bg-graphic__wrap h4,
.bg-graphic .bg-graphic__wrap h5,
.bg-graphic .bg-graphic__wrap h6 {
  margin-bottom: 1.5rem;
}
.bg-graphic--secondary *, .bg-graphic--tertiary * {
  color: #ffffff;
}
.bg-graphic--secondary .bg-graphic__wrap {
  background-color: #5341f0;
}
.bg-graphic--tertiary .bg-graphic__wrap {
  background-color: #61e387;
}
.bg-graphic--center {
  text-align: center;
}
.bg-graphic--1 .bg-graphic__content {
  position: relative;
  z-index: 20;
}
.bg-graphic--1 .graphic-bg {
  position: absolute;
  z-index: 10;
}
.bg-graphic--1 .graphic-bg--1 {
  left: -403px;
  top: -425px;
}
@media (min-width: 960px) {
  .bg-graphic--1 .graphic-bg--1 {
    left: -410px;
    top: -345px;
  }
}
@media (min-width: 1600px) {
  .bg-graphic--1 .graphic-bg--1 {
    left: -394px;
    top: -274px;
  }
}
.bg-graphic--1 .graphic-bg--2 {
  right: -410px;
  top: -121px;
}
@media (min-width: 768px) {
  .bg-graphic--1 .graphic-bg--2 {
    right: -317px;
    top: -121px;
  }
}
@media (min-width: 1600px) {
  .bg-graphic--1 .graphic-bg--2 {
    right: -145px;
    top: -142px;
  }
}
.bg-graphic--2, .bg-graphic--3 {
  position: relative;
  overflow: hidden;
  padding: 3rem;
  text-align: center;
  background-color: #ffffff;
}
.bg-graphic--2 .bg-graphic__content, .bg-graphic--3 .bg-graphic__content {
  position: relative;
  z-index: 20;
}
.bg-graphic--2 svg, .bg-graphic--3 svg {
  position: absolute;
  z-index: 10;
  top: -320px;
  left: -375px;
}
.bg-graphic--3 svg {
  width: 638px;
  height: auto;
  top: 50px;
  right: -430px;
  left: auto;
}
@media (min-width: 480px) {
  .bg-graphic--3 svg {
    right: -378px;
  }
}
@media (min-width: 768px) {
  .bg-graphic--3 svg {
    right: -430px;
  }
}
@media (min-width: 960px) {
  .bg-graphic--3 svg {
    right: -378px;
  }
}

/* Gallery / Testimoniaks 
================================================== */
.section--gallery-testimonials .row .column, .section--gallery-testimonials .row .columns, .section--gallery-testimonials .row .newslist .latestnews-item, .newslist .section--gallery-testimonials .row .latestnews-item {
  position: relative;
}
.section--gallery-testimonials .row .column::after, .section--gallery-testimonials .row .columns::after, .section--gallery-testimonials .row .newslist .latestnews-item::after, .newslist .section--gallery-testimonials .row .latestnews-item::after {
  content: "";
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0.9375rem;
  bottom: 6rem;
  left: 0.9375rem;
  margin: auto;
  background-color: #141747;
}
@media (min-width: 768px) {
  .section--gallery-testimonials .row .column::after, .section--gallery-testimonials .row .columns::after, .section--gallery-testimonials .row .newslist .latestnews-item::after, .newslist .section--gallery-testimonials .row .latestnews-item::after {
    top: 6rem;
  }
}

.testimonials-slider {
  z-index: 30;
  top: 3rem;
  left: 2rem;
  width: 85% !important;
  margin-bottom: 8rem;
}
@media (min-width: 768px) {
  .testimonials-slider {
    position: absolute !important;
    top: 9rem;
    left: 4%;
    width: 35% !important;
  }
}
@media (min-width: 1280px) {
  .testimonials-slider {
    top: 11rem;
    left: 8%;
  }
}
.testimonials-slider__logo {
  padding: 1rem;
  background-color: #ffffff;
}
.testimonials-slider__quote p {
  font-size: 1.2rem !important;
}
@media (min-width: 576px) {
  .testimonials-slider__quote p {
    font-size: 1rem !important;
  }
}
@media (min-width: 1280px) {
  .testimonials-slider__quote p {
    font-size: 1.1rem !important;
  }
}
@media (max-width: 575px) {
  .testimonials-slider__icon {
    left: auto !important;
    right: 0 !important;
  }
}
@media (max-width: 575px) {
  .testimonials-slider__icon img {
    rotate: 180deg;
  }
}
.testimonials-slider #n2-ss-6 {
  /*.n2-ss-slide-limiter {
    max-width: 85% !important;
    @include bp(mlarge) {
      max-width: 35% !important;
    }
  }*/
}
.testimonials-slider #n2-ss-6 .n2-ss-layers-container {
  margin-right: auto;
}
@media (max-width: 575px) {
  .testimonials-slider #n2-ss-6 .n2-ss-layer[data-sstype=slide] {
    padding-left: 0 !important;
  }
}
.testimonials-slider .n2-ss-control-bullet {
  top: 3rem !important;
  margin-left: 3.2rem !important;
}

.gallery-slider {
  position: relative;
  z-index: 20;
  padding: 0 2rem;
}
@media (min-width: 576px) {
  .gallery-slider {
    padding: 0 4rem;
  }
}
.gallery-slider .n2-ss-slider-1 {
  width: 100%;
  overflow: hidden;
  margin-bottom: 5rem;
  margin-left: auto;
}
@media (min-width: 768px) {
  .gallery-slider .n2-ss-slider-1 {
    width: 50%;
    margin-bottom: 7rem;
  }
}
@media (min-width: 960px) {
  .gallery-slider .n2-ss-slider-1 {
    margin-bottom: 5rem;
  }
}
.gallery-slider .nextend-thumbnail {
  overflow: visible !important;
}
.gallery-slider .nextend-thumbnail-button {
  overflow: hidden;
  top: -30%;
  left: auto !important;
  width: auto !important;
  height: auto !important;
  padding: 1rem;
  background-color: #ffffff;
  transform: none !important;
  visibility: visible !important;
}
@media (min-width: 768px) {
  .gallery-slider .nextend-thumbnail-button {
    top: -21%;
  }
}
@media (min-width: 960px) {
  .gallery-slider .nextend-thumbnail-button {
    top: -16%;
  }
}
.gallery-slider .nextend-thumbnail-button img {
  width: 22px !important;
}
.gallery-slider .nextend-thumbnail-previous {
  right: 80px;
}

/* Tech Areas Slider
================================================== */
.tech-areas-slider {
  overflow-x: hidden;
  padding: 3rem 0;
}
.tech-areas-slider .n2-ss-slider-2,
.tech-areas-slider .n2-ss-slider-3 {
  overflow: visible !important;
}
.tech-areas-slider .n2-ss-slide {
  overflow: hidden !important;
  border-radius: 8px !important;
}
.tech-areas-slider .n2-ss-slide img {
  max-width: 100px !important;
}

/* News
================================================== */
.newslist .latestnews-items {
  flex-flow: column !important;
  align-items: normal !important;
  -webkit-justify-content: normal !important;
  -ms-flex-pack: normal !important;
  justify-content: normal !important;
  overflow: visible !important;
}
@media (min-width: 576px) {
  .newslist .latestnews-items {
    flex-flow: row wrap !important;
  }
}
.newslist .latestnews-item {
  flex: 1;
  padding: 0 0 2rem !important;
}
@media (min-width: 576px) {
  .newslist .latestnews-item {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
}
@media (min-width: 960px) {
  .newslist .latestnews-item {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
}
.newslist .latestnews-item > a {
  display: block;
  height: 100%;
}
.newslist .latestnews-item > a:hover .detail_category,
.newslist .latestnews-item > a:hover .detail_tags {
  opacity: 1;
}
.newslist .latestnews-item > a:hover .link a {
  background-color: #5341f0;
}
.newslist .latestnews-item > a:hover .link a span {
  color: #ffffff;
}
.newsSection .newslist .latestnews-item {
  border: 0;
}
.newslist .latestnews-item.active {
  opacity: 1 !important;
}
.newslist .newshead {
  float: none !important;
  margin: 0 !important;
}
.newslist picture {
  display: block;
}
.newslist .innerpicture {
  width: 100%;
}
.newslist .innerpicture:after {
  content: "";
  display: block;
  padding-bottom: 60%;
}
.newslist .innerpicture img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.newslist .news {
  position: relative !important;
  height: 100% !important;
  margin: 0 !important;
  padding: 0rem !important;
  background-color: #ffffff !important;
  border: none !important;
  overflow: hidden;
}
.newslist .newstitle {
  font-size: 1.6rem;
  font-family: "Work Sans", sans-serif !important;
  font-weight: normal !important;
  line-height: 1.2 !important;
  margin: 0.5rem 0 !important;
}
.newslist .newstitle a {
  color: #141747;
}
.newslist .newstitle a:hover {
  color: #5341f0;
}
.newslist .newsinfo {
  padding: 1.5rem 1rem 4rem !important;
}
.newslist .newsinfo * {
  color: #141747;
}
.newslist .newsintro {
  line-height: 1.5 !important;
}
.newslist .detail_category,
.newslist .detail_tags {
  opacity: 1;
  display: inline-flex;
  margin-right: 0.5rem;
  padding: 0.4rem 0.8rem;
  border-radius: 50px;
  background: #edf0ef;
  font-family: "Work Sans", sans-serif;
  font-weight: 700;
  font-size: 0.8rem;
  color: #141747;
  transition: 0.5s ease-in-out;
}
@media (min-width: 576px) {
  .newslist .detail_category,
  .newslist .detail_tags {
    opacity: 0.25;
  }
}
.newslist .detail_date {
  display: inline-flex;
  align-items: center;
  margin: 0 0 0.25rem 0;
  font-size: 0.8rem;
}
.newslist .detail_date span {
  font-weight: 500;
  color: #8a8a8a;
}
.newslist .delimiter {
  display: none;
}
.newslist .link {
  width: 100% !important;
  margin-top: 1.5em !important;
}
.newslist .link a {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0.5rem 0;
  background-color: #141747;
  text-align: center;
  font-family: "Work Sans", sans-serif;
  text-transform: uppercase;
  font-weight: 400;
}
.newslist .link span {
  color: #ffffff;
  transition: 0.5s ease-in-out;
}
.newslist .items_pagination {
  margin-top: 2rem !important;
}
.newslist .items_pagination a {
  display: inline-block;
  width: 40px;
  margin: 0 10px 0 0 !important;
  padding: 10px 0;
  background-color: #eeeeee;
  border-radius: 50%;
  box-sizing: content-box;
  color: #141747;
  cursor: pointer;
  font-weight: bold;
  line-height: 18px;
  text-align: center;
}
.newslist .items_pagination a:hover {
  color: #ffffff;
  background-color: #141747;
}
.newslist .items_pagination .active_page {
  text-decoration: none !important;
}
.newslist .items_pagination .active_page a {
  color: #ffffff;
  background-color: #141747;
}
@media (min-width: 576px) {
  .newslist--full-width .latestnews-item {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}
.newslist--full-details .latestnews-item .newsinfo {
  padding: 1.5rem 1rem !important;
}
.newslist--full-details .latestnews-item ul,
.newslist--full-details .latestnews-item p {
  margin-bottom: 1rem !important;
}
.newslist--contain .picture {
  margin: auto;
}
.newslist--contain .innerpicture img {
  object-fit: contain;
}
.newslist--size-down .picture {
  margin: auto;
}
.newslist--size-down .innerpicture img {
  object-fit: scale-down;
  max-width: 80% !important;
  margin: 5% 10% !important;
}
.newslist--small-card .newshead {
  margin-top: 1.5rem !important;
}
.newslist--small-card .newsinfo {
  padding: 1.5rem !important;
}
.newslist--center .newstitle,
.newslist--center .newsintro {
  text-align: center !important;
}
.newslist--quotes .latestnews-item {
  flex: 0 0 100% !important;
  max-width: 100% !important;
}
@media (min-width: 768px) {
  .newslist--quotes .latestnews-item {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
}
.newslist--quotes .newslist__quote {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 2rem;
  left: 2rem;
}
.newslist--quotes .picturetype {
  position: absolute !important;
  top: 1rem;
  right: 1rem;
  width: 200px;
  height: 120px;
}
.newslist--quotes .picture {
  margin: auto;
}
.newslist--quotes .innerpicture img {
  object-fit: scale-down;
  max-width: 80% !important;
  margin: 5% 10% !important;
}
.newslist--quotes .newsinfo {
  margin-top: 8rem;
  padding: 2rem !important;
  font-size: 1.1rem;
}
.newslist--quotes .newsextra {
  display: block;
  margin-top: 2rem !important;
  font-size: 0.8rem !important;
}
.newslist--quotes .newsextra .detail_data {
  display: inline-flex;
  margin-right: 0.25rem;
  text-transform: uppercase;
}
.newslist--profiles .picture {
  width: 100%;
  height: 100%;
  max-width: none !important;
  max-height: none !important;
}
.newslist--profiles .innerpicture,
.newslist--profiles picture {
  width: 140px;
  height: 140px;
  margin: auto;
  overflow: hidden;
  border-radius: 50%;
}
.newslist--profiles img {
  position: static !important;
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
}

/* News Page
  ================================================== */
.news-item-article .main-content,
.article--narrow .main-content {
  flex-direction: column;
  padding: 0;
}
.news-item-article .published,
.article--narrow .published {
  margin: 0;
  font-family: "Work Sans", sans-serif;
  text-transform: uppercase;
  font-weight: 400;
}
.news-item-article .com-content-image,
.news-item-article .item-page,
.article--narrow .com-content-image,
.article--narrow .item-page {
  max-width: 960px;
  width: 100%;
  margin: auto;
}
.news-item-article .com-content-image,
.article--narrow .com-content-image {
  margin-bottom: 0;
  border-bottom: 2px solid #fafafa;
}
.news-item-article .com-content-image figure,
.article--narrow .com-content-image figure {
  position: relative;
  margin: 0;
  background-color: #ffffff;
}
.news-item-article .com-content-image img,
.article--narrow .com-content-image img {
  display: block;
  width: 100%;
  height: 100%;
  max-height: 600px;
  object-fit: cover;
}
.news-item-article .item-page,
.article--narrow .item-page {
  padding: 3em;
  background-color: #ffffff;
}
.news-item-article .item-page *,
.article--narrow .item-page * {
  color: #0a0a0a;
}
.news-item-article .page-header h1,
.article--narrow .page-header h1 {
  margin-top: 0;
}
.news-item-article .article-info.muted,
.article--narrow .article-info.muted {
  display: block;
  color: #141747;
  margin-bottom: 2em;
}

.article--image-contain .com-content-image figure {
  padding: 3rem;
}
.article--image-contain .com-content-image img {
  object-fit: contain;
  max-height: 400px;
}

.sharethis-inline-share-buttons {
  margin-top: 4rem;
  margin-bottom: 2rem;
}

.article-info.muted {
  display: none;
}

/* Sponsors Section
================================================== */
.sponsors-section {
  /*.sponsor {
    &__logo {
      img {
        max-height: 80px !important;
        width: auto;
      }
    }
  }*/
}
.sponsors-section__wrap {
  padding: 3rem;
  background-color: #ffffff;
}
.sponsors-section [data-sstype=layer] img {
  max-height: 80px !important;
  width: auto;
}

/* Footer
================================================== */
footer {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-top: 3rem;
  padding: 10em 0 0;
  background: #141747;
}
@media (min-width: 960px) {
  footer {
    padding-top: 6rem;
  }
}
footer * {
  color: #ffffff;
}
footer a:hover {
  color: #5341f0;
}
footer .button {
  margin: 2rem 0 0 0;
  font-size: 0.9rem;
}

.footer__outer-wrap {
  position: relative;
  z-index: 20;
}
.footer__wrap {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  text-align: center;
  justify-content: space-between;
}
@media (max-width: 575px) {
  .footer__wrap > div {
    text-align: center;
  }
}
@media (max-width: 1279px) {
  .footer__wrap > div {
    margin-bottom: 3rem;
  }
}
.footer__wrap h3 {
  margin: 0 0 2rem;
  font-size: 1.2rem;
  font-weight: 600;
}
.footer__wrap h4 {
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  font-weight: normal;
}
.footer__logo img {
  max-width: 220px;
  height: auto;
  margin: 0 auto 2rem;
}
@media (min-width: 1280px) {
  .footer__logo img {
    margin: 0 0 2rem 0;
  }
}
.footer__logo .social svg {
  width: 1.6rem;
  height: 1.6rem;
}
.footer__logo .social svg path {
  fill: #ffffff;
}
.footer__quick-links, .footer__menu {
  text-align: left;
}
.footer__quick-links svg, .footer__menu svg {
  width: auto;
  max-height: 72px;
  max-width: 260px;
  margin-bottom: 2rem;
}
.footer__quick-links svg *, .footer__menu svg * {
  fill: #ffffff !important;
}
.footer__quick-links a, .footer__menu a {
  color: #ffffff;
}
.footer__quick-links a:hover, .footer__menu a:hover {
  color: #5341f0;
}
.footer__menu {
  align-self: flex-start;
}
@media (min-width: 576px) {
  .footer__menu--2 {
    margin-right: 5rem;
  }
}
.footer__menu .nav {
  display: flex !important;
  flex-wrap: wrap;
  align-items: normal;
}
.footer__menu .nav > li {
  flex: 0 0 100%;
  max-width: 100%;
  margin: 0 0 2rem 0;
}
@media (min-width: 480px) {
  .footer__menu .nav > li {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (min-width: 768px) {
  .footer__menu .nav > li {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
}
@media (min-width: 1280px) {
  .footer__menu .nav > li {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
}
.footer__menu .nav .parent > a:after {
  display: none;
}
.footer__menu .nav--full .nav > li {
  flex: 0 0 100% !important;
  max-width: 100% !important;
}
.footer__menu .nav .item-132,
.footer__menu .nav .item-133,
.footer__menu .nav .item-196 {
  display: none;
}
.footer__menu .nav .item-126,
.footer__menu .nav .item-131 {
  display: none;
}
.footer__menu .nav a:hover,
.footer__menu .nav .current > a,
.footer__menu .nav .active > a {
  border-color: transparent;
}
.footer__menu .nav a:hover:after,
.footer__menu .nav .current > a:after,
.footer__menu .nav .active > a:after {
  border-color: transparent;
}
.footer__menu .nav a {
  text-align: left;
}
.footer__menu .nav a:hover {
  color: #61e387;
}
.footer__menu .nav > li > a {
  margin-bottom: 1rem;
  text-transform: capitalize;
  font-weight: bold;
}
.footer__menu .nav .mod-menu__sub {
  display: block !important;
}
.footer__menu .nav .mod-menu__sub a {
  font-size: 0.8rem;
}
.footer__menu .button--ticket,
.footer__menu .nav__search {
  display: none !important;
}
.footer__social {
  margin: 0 !important;
}
@media (min-width: 960px) {
  .footer__social {
    padding-right: 4rem;
  }
}
@media (min-width: 960px) {
  .footer__social ul {
    flex-flow: column;
  }
}
@media (min-width: 960px) {
  .footer__social ul li {
    margin-left: auto;
  }
}
.footer__social ul li a {
  display: block;
  padding: 1rem;
}
.footer__social ul li svg {
  width: 1.6rem;
  height: 1.6rem;
}
.footer__social ul li svg path {
  fill: #ffffff;
}
.footer__logo {
  order: 3;
}
@media (min-width: 960px) {
  .footer__logo {
    order: 1;
  }
}
.footer__quick-links, .footer__menu {
  order: 1;
}
@media (min-width: 960px) {
  .footer__quick-links, .footer__menu {
    order: 2;
  }
}
.footer__social {
  order: 4;
}
@media (min-width: 960px) {
  .footer__social {
    order: 4;
  }
}
.footer__graphic {
  position: absolute;
  z-index: 10;
}
.footer__graphic--1 {
  bottom: -220px;
  right: -480px;
}
.footer__graphic--2 {
  left: -772px;
  top: -555px;
}
@media (min-width: 1800px) {
  .footer__graphic--2 {
    top: -440px;
  }
}
.footer__graphic--3 {
  top: -90px;
  left: -200px;
}
@media (min-width: 576px) {
  .footer__graphic--3 {
    top: -100px;
    left: -300px;
  }
}
@media (min-width: 1600px) {
  .footer__graphic--3 {
    left: -200px;
  }
}
.footer__graphic--4 {
  right: -200px;
  top: -400px;
  height: 200%;
}
@media (min-width: 576px) {
  .footer__graphic--4 {
    right: -350px;
    top: 300px;
    height: 100%;
  }
}
@media (min-width: 960px) {
  .footer__graphic--4 {
    top: 0;
    right: -150px;
  }
}
@media (min-width: 1600px) {
  .footer__graphic--4 {
    right: 0;
  }
}
.footer__graphic--5 {
  width: 800px;
  height: 700px;
  right: -140px;
  bottom: -200px;
  /*@include bp(medium) {
    top: 300px;
    right:-350px;
  }*/
  /*@include bp(xxlarge) {
    top: 150px;
    right: -200px;
  }*/
}
@media (min-width: 576px) {
  .footer__graphic--5 {
    bottom: -320px;
  }
}
.footer__graphic--6 {
  width: 100%;
  bottom: 60px;
}
.footer--dark {
  background-color: #111111;
}
.footer__bottom {
  position: relative;
  z-index: 30;
  width: 100%;
  margin-top: 6rem;
  padding: 1rem 0;
  background-color: rgb(13.2747252747, 15.2659340659, 47.1252747253);
}
.footer__bottom * {
  text-transform: uppercase;
}
.footer__bottom .row {
  justify-content: space-between;
  align-items: center;
}
.footer__bottom .footer__links,
.footer__bottom .footer__signature {
  font-size: 0.8em;
}
@media (max-width: 575px) {
  .footer__bottom .footer__links,
  .footer__bottom .footer__signature {
    padding: 0.5rem 0;
    text-align: center;
  }
}
@media (min-width: 576px) {
  .footer__bottom .footer__links {
    justify-content: flex-start;
  }
}
.footer__bottom .footer__links a {
  margin-right: 1rem;
}
.footer__bottom .footer__links a:hover {
  color: #61e387;
}
.footer__bottom .footer__signature {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 576px) {
  .footer__bottom .footer__signature {
    justify-content: flex-end;
  }
}
.footer__bottom .footer__signature img {
  margin-left: 1rem;
  width: 14em;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

#ot-sdk-btn.ot-sdk-show-settings,
#ot-sdk-btn.optanon-show-settings {
  padding: 0 !important;
  color: #ffffff !important;
  font-size: 1em !important;
  border: none !important;
  background-color: transparent !important;
  transition: 0.5s ease-in-out !important;
}
#ot-sdk-btn.ot-sdk-show-settings:hover,
#ot-sdk-btn.optanon-show-settings:hover {
  color: #61e387 !important;
}

/* Popup
================================================== */
.popup-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 1rem;
  align-items: center;
  justify-content: center;
  z-index: 999;
}
@media (max-width: 575px) {
  .popup-overlay {
    display: none !important;
  }
}
.popup-overlay .popup {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 700px;
  background: url("../images/pop-up-bg.webp") no-repeat 50% 50%;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
@media (max-width: 575px) {
  .popup-overlay .popup {
    font-size: 0.8em;
  }
}
.popup-overlay .popup * {
  color: #ffffff;
}
.popup-overlay .popup__link {
  padding: 4em 2em;
}
.popup-overlay .popup__logo {
  margin: 0 auto 1rem auto;
}
@media (max-width: 767px) {
  .popup-overlay .popup__logo {
    margin-top: 2rem;
  }
}
.popup-overlay .popup__logo img {
  display: block;
}
.popup-overlay .popup__logos {
  align-items: center;
  flex-wrap: wrap;
}
.popup-overlay .popup__logos img {
  width: auto;
  max-width: 140px;
  max-height: 72px;
  margin: 0 1rem;
}
.popup-overlay .popup--no-padding .popup__link {
  padding: 0 !important;
}
.popup-overlay .title {
  margin-bottom: 8px;
}
@media (max-width: 575px) {
  .popup-overlay .title {
    font-size: 1.4em;
  }
}
.popup-overlay .title:after {
  display: none;
}
.popup-overlay h3 {
  margin-bottom: 1rem;
}
.popup-overlay .button {
  margin-bottom: 0;
}
.popup-overlay .close {
  position: absolute;
  top: 1em;
  right: 1em;
  height: auto;
  margin: 0;
  padding: 0.25rem 1rem;
  line-height: 100%;
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
  font-family: sans-serif;
}
.popup-overlay .close:after {
  display: none;
}
.popup-overlay--2 .popup {
  background: #0a0a0a;
}