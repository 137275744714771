button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  padding: 0.75rem;
  border: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

/* Balbooa Forms Component 
================================================== */
.com-baforms-wrapper {
  .ba-form-page {
    .ba-form-field-item {
      margin: 0 0 1rem 0;
    }
    fieldset {
      padding: 0;
      border: none;
    }
    .ba-form-field-item
      .ba-form-products-cart
      .ba-form-product-quantity-cell
      input,
    .ba-form-page-navigation-title,
    .upload-file-btn,
    .upload-file-input,
    .ba-field-container select,
    .ba-field-container textarea[readonly],
    .ba-field-container textarea,
    .ba-field-container input[type="text"],
    .ba-field-container input[type="number"],
    .ba-field-container input[type="password"],
    .ba-field-container input[type="email"],
    .ba-field-container select option,
    .ba-form-field-item .ba-form-checkbox-wrapper .ba-checkbox-title {
      font-weight: normal;
      font-size: 1rem !important;
      color: $base-font-color !important;
    }
    .ba-form-acceptance-field .ba-field-container {
      margin-top: 2rem;
    }
    .ba-form-submit-wrapper .ba-form-submit-btn-wrapper {
      width: auto !important;
    }
    .ba-form-submit-field {
      margin-bottom: 0 !important;
    }
    .ba-form-submit-btn {
      margin-top: 2rem;
      margin-bottom: 0;
    }
  }
}

/* Search
================================================== */
.com-finder__search {
  padding: 0;
}
.com-finder__search-legend {
  margin: 1.5rem 0 2rem;
  font-size: 3rem;
}
#search-result-list,
.awesomplete {
  mark {
    background-color: $secondary-colour;
    color: $white;
  }
}
