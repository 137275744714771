.button,
button,
input[type="submit"],
input[type="reset"],
input[type="button"],
.com-baforms-wrapper .ba-form-submit-btn,
#n2-ss-2 .n2-ss-button-container .button {
  position: relative;
  overflow: hidden;
  display: inline-block;
  margin-bottom: 1.5rem;
  padding: 1.25rem 1.5rem !important;
  background: $secondary-colour;
  border: 0;
  color: $white !important;
  font-size: 0.9rem !important;
  font-weight: normal;
  text-decoration: none;
  line-height: normal;
  font-family: $primary-font;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  outline: none;
  border-radius: 8px;
  @extend %box-shadow;
  @include transition($global-transition);
  -webkit-appearance: none;

  * {
    color: $white;
  }

  .ripple {
    position: absolute;
    background: $white;
    transform: translate(-50%, -50%);
    pointer-events: none;
    border-radius: 50%;
    animation: ripple 1s ease-out infinite;
  }
  @keyframes ripple {
    0% {
      width: 0;
      height: 0;
      opacity: 0.5;
    }
    100% {
      width: 500px;
      height: 500px;
      opacity: 0;
    }
  }

  &:hover {
    color: $white;
    border: 0;
  }
  &:active {
    border: 0;
  }
  &:visited {
    color: $white;
  }
}

.button {
  &--full-width {
    width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
    text-align: center;
  }
  &--secondary {
    background: $tertiary-colour;
  }
  &--black {
    background: $primary-colour;
  }
  &--white {
    background: $white;
    color: $primary-colour !important;
    .ripple {
      background-color: $secondary-colour;
    }
  }
  &-wrap {
    &--center {
      text-align: center;
    }
  }
  &--margin {
    margin: 0.5rem;
  }
}

/* Fix for odd Mozilla border & padding issues */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/* Onstrust Popup
================================================== */
#onetrust-banner-sdk {
  outline: none !important;
  button {
    background: $secondary-colour !important;
    font-family: $primary-font !important;
    font-weight: normal !important;
    text-decoration: none !important;
    border-radius: 8px !important;
    outline: none !important;
    @extend %box-shadow;
    @include transition($global-transition);
    *:hover {
      border: 0 !important;
    }
    &#onetrust-pc-btn-handler.cookie-setting-link {
      background-color: $primary-colour !important;
    }
  }
}
